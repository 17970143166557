import { RedirectToKinde } from "@/components/forms/login-form/redirect-to-kinde";
import { Footer } from "@/components/layouts/footer/footer";
import { LoginHeader } from "@/components/layouts/header/login-header";

import { T } from "@transifex/react";

export const LoginPage: React.FC = () => {
  return (
    <div className="w-screen h-screen light">
      <LoginHeader />
      <div className="min-h-[70vh] flex flex-col justify-center">
        <div className="flex flex-col items-center space-y-16">
          <h1 className="text-3xl font-semibold tracking-tight mx-auto">
            <T _str="Elton Portal" />
          </h1>
          <p className="text-lg mx-auto px-2 md:px-16 md:max-w-[800px] text-center">
            <T _str="Introducing Elton Portal: A tool for managing your business e-mobility. Access real-time data and streamline operations effortlessly." />
          </p>
          <RedirectToKinde className="px-2" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

import { Key } from "react";
import { FieldApi } from "@tanstack/react-form";

import {
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@elton/ui/core";
import { cn } from "@elton/ui/lib/utils";

import { FieldInfo } from "./field-info/field-info";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TextFieldProps = FieldApi<any, any, any, any, any>;

interface ISelectProps<TData> {
  keyExtractor: { key: keyof TData; value: keyof TData };
  renderItem?: (_: TData) => React.ReactNode;
  field: TextFieldProps;
  placeholder?: string;
  label: string;
  data: TData[];
  className?: string;
}

export function SelectField<TData>({
  field,
  label,
  data,
  placeholder = "",
  keyExtractor: extractor,
  renderItem = (value: TData) => value[extractor.value] as string,
  className,
}: ISelectProps<TData>) {
  return (
    <div className={cn("space-y-2", className)}>
      <Label>{label}</Label>
      <Select
        onValueChange={(value) => {
          field.handleChange(value);
        }}
        defaultValue={field.state.value}
      >
        <SelectTrigger>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {data?.map((value) => {
              return (
                <SelectItem
                  key={value[extractor.key] as Key}
                  value={value[extractor.key] as string}
                >
                  {renderItem(value)}
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectContent>
      </Select>
      <FieldInfo field={field} />
    </div>
  );
}

import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";

import { CreateCompany } from "@elton/types";

interface IProps {
  data: CreateCompany;
  onSubmit: (value: CreateCompany) => void;
}

export const useCompanyPartialForm = ({ data, onSubmit }: IProps) => {
  const form = useForm({
    defaultValues: data,
    validatorAdapter: zodValidator(),
    onSubmit: (values) => {
      return onSubmit(values.value);
    },
  });

  return form;
};

import { z } from "zod";

export type SpendingsSequence = {
  date: string;
  NOK: number;
  SEK: number;
  DKK: number;
  EUR: number;
};

export const dailySpendingSchema = z.object({
  date: z
    .string()
    .transform(
      (dateString) => new Date(dateString).toISOString().split("T")[0]
    ),
  NOK: z.number().min(0).catch(0),
  SEK: z.number().min(0).catch(0),
  DKK: z.number().min(0).catch(0),
  EUR: z.number().min(0).catch(0),
});

export type DailySpending = z.infer<typeof dailySpendingSchema>;

import { useCallback } from "react";
import { useNavigate } from "@tanstack/react-router";
import debounce from "debounce";

export const useDebouncedSearchNavigation = (initialPath: string) => {
  const navigate = useNavigate({ from: initialPath });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedNavigate = useCallback(
    debounce((filterValue: string) => {
      navigate({
        params: {},
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        search: (prevSearchParams: any) => {
          /**
           * When the filter value changes, we need to reset the offset and limit
           */
          if (prevSearchParams.filter !== filterValue) {
            return {
              ...prevSearchParams,
              offset: 0,
              limit: 10,
              filter: filterValue,
            };
          }
          return {
            ...prevSearchParams,
            filter: filterValue,
          };
        },
      });
    }, 500),
    [navigate]
  );

  const removeOpenParameter = useCallback(() => {
    navigate({
      params: {},
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      search: (prevSearchParams: any) => ({
        ...prevSearchParams,
        open: undefined,
      }),
    });
  }, [navigate]);

  return {
    updateSearchFilter: debouncedNavigate,
    removeOpenParameter,
  };
};

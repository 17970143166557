import { useState } from "react";

import { CreateCompany, ListCompany } from "@elton/types";
import { defaultCreateCompany } from "@elton/types/mocks";
import {
  AlertDialog,
  SearchInput,
  Sheet,
  SheetContent,
  useToast,
} from "@elton/ui/core";

import { CreateCompanyForm } from "@/components/forms/company-form/default/create-company-form";
import { useCompanyColumns } from "@/components/tables/columns/company-columns";
import { DynamicTable } from "@/components/tables/dynamic-table/dynamic-table";

import { useDebouncedSearchNavigation } from "@/hooks/search/use-search-navigation";

import { DefaultDialogContent } from "../_common/default-alert-content";
import { DefaultTopSectionContent } from "../_common/default-top-section-content";
import { BlankLayout } from "../_layouts/blank-layout/blank-layout";

import { useCreateCompanyMutation } from "@/api/mutations/company/create-company-mutation";
import { useDeleteCompanyMutation } from "@/api/mutations/company/delete-company-mutation";
import { DefaultListParams } from "@/api/params/default-list-params";
import { Route as CompaniesRoute } from "@/routes/_authenticated/companies";
import { useT } from "@transifex/react";

export const CompaniesPage: React.FC = () => {
  const t = useT();
  const { toast } = useToast();

  const loaderData = CompaniesRoute.useLoaderData();
  const searchParams = CompaniesRoute.useSearch<DefaultListParams>();

  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState<CreateCompany>(defaultCreateCompany);
  const [selected, setSelected] = useState<ListCompany | null>(null);

  const createCompany = useCreateCompanyMutation();
  const deleteCompany = useDeleteCompanyMutation();

  const columns = useCompanyColumns({
    onDelete: (companyId: string) => {
      const found = loaderData.companies?.data?.find(
        (company) => company.id === companyId
      );
      if (found) {
        setSelected(found);
      }
    },
    onCopyId: (companyId: string) => {
      navigator.clipboard.writeText(companyId);
      toast({
        variant: "info",
        description: t("ID copied to the clipboard."),
      });
    },
  });

  const { updateSearchFilter } = useDebouncedSearchNavigation(
    CompaniesRoute.fullPath
  );

  const handleProceedWithDeletion = () => {
    if (selected) {
      deleteCompany.mutate(selected.id!);
    }
  };

  const handleCreateCompany = async (data: CreateCompany) => {
    await createCompany.mutateAsync(data);
    setFormOpen(false);
  };

  return (
    <BlankLayout>
      <AlertDialog>
        <Sheet open={formOpen} onOpenChange={setFormOpen}>
          <div className="w-full">
            <DefaultTopSectionContent
              title={t("Companies")}
              description={t(
                "Manage all your companies - Click a company to inspect or make changes."
              )}
              buttonTitle={t("Create company")}
              onClick={() => setFormData(defaultCreateCompany)}
            />
            <div className="relative w-full">
              <DynamicTable
                renderToolbar={() => {
                  return (
                    <>
                      <SearchInput
                        defaultValue={searchParams.filter}
                        containerClassName="w-full sm:max-w-96"
                        placeholder={t("Search") + ".."}
                        onChange={updateSearchFilter}
                      />
                    </>
                  );
                }}
                columns={columns}
                data={loaderData.companies.data}
                total={loaderData.companies.total}
                params={searchParams}
              />
            </div>
          </div>
          <SheetContent className="space-y-4">
            <CreateCompanyForm
              loading={createCompany.isPending}
              data={formData}
              paymentPlans={loaderData.paymentPlans.data}
              onCreate={handleCreateCompany}
              onUpdate={() => {}}
            />
          </SheetContent>
        </Sheet>
        <DefaultDialogContent
          title={t("Do you want to proceed?")}
          description={t(
            "This action cannot be undone. This will permanently delete {name} from your organization.",
            { name: selected?.name }
          )}
          onContinue={handleProceedWithDeletion}
        />
      </AlertDialog>
    </BlankLayout>
  );
};

import { forwardRef } from "react";

import { Button } from "@elton/ui/core";
import { ArrowDownAZ, ArrowUpAZ, ArrowUpDown } from "@elton/ui/icons";
import { cn } from "@elton/ui/lib/utils";

type SortOrder = "asc" | "desc";
type Ref = HTMLButtonElement;
type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: SortOrder;
};

export const SortButton = forwardRef<Ref, Props>(
  ({ children, className, variant, ...rest }, ref) => {
    return (
      <Button
        variant="ghost"
        className={cn("-ml-4", className)}
        ref={ref}
        {...rest}
      >
        {children}
        {SortIcon[variant ?? "default"]}
      </Button>
    );
  }
);

const SortIcon: Record<SortOrder | "default", JSX.Element> = {
  default: <ArrowUpDown className="ml-2 h-3 w-auto" />,
  asc: <ArrowDownAZ className="ml-2 h-3 w-auto" />,
  desc: <ArrowUpAZ className="ml-2 h-3 w-auto" />,
};

import { cn } from "@ui/lib/utils";

interface IProps {
  className?: string;
  data: {
    title: string;
    value: string;
    disabled?: boolean;
  }[];
}

export const DataList: React.FC<IProps> = ({ className, data }) => {
  return (
    <dl className={cn(ClassNames.List, className)}>
      {data.map((item, index) => (
        <div className={ClassNames.Entry} key={index}>
          <dt className={ClassNames.Title}>{item.title}</dt>
          <dd className={ClassNames.Value}>{item.value}</dd>
        </div>
      ))}
    </dl>
  );
};

const ClassNames = {
  List: "border rounded",
  Entry: "border-b p-4 align-middle h-[65px] truncate flex text-sm",
  Title: "w-[30%] font-medium leading-none flex items-center",
  Value: "leading-none flex items-center",
};

import { createFileRoute } from "@tanstack/react-router";
import { format } from "date-fns";
import { z } from "zod";

import {
  Company,
  CompanyMetrics,
  Currency,
  SpendingsSequence,
} from "@elton/types";
import { getMonthlyDateRange, SpendingsAccumulator } from "@elton/utils";

import { DashboardPage } from "@/pages/dashboard-page/dashboard-page";
import { ErrorPage } from "@/pages/error-page/error-page";

import { getCompany } from "@/api/requests/companies/get-company";
import { getSpendingsSessions } from "@/api/requests/spendings/get-spendings";

const DashboardParamsSchema = z.object({
  date: z
    .string()
    .date()
    .optional()
    .catch(format(new Date(), "yyyy-MM-dd"))
    .default(format(new Date(), "yyyy-MM-dd")),
});

interface IProps {
  company: Company;
  sequences: SpendingsSequence[];
  acc: Record<Currency, number>;
  metrics: CompanyMetrics;
}

export const Route = createFileRoute(
  "/_authenticated/companies/$companyId/_dashboard/dashboard"
)({
  validateSearch: DashboardParamsSchema,
  loaderDeps: (opts) => DashboardParamsSchema.parse(opts.search),
  loader: async (props): Promise<IProps> => {
    const company = await getCompany(props.params.companyId);
    const dateRange = getMonthlyDateRange(new Date(props.deps.date!));
    const sessions = await getSpendingsSessions(props.params.companyId, {
      dateFrom: dateRange.start?.toISOString(),
      dateTo: dateRange.end?.toISOString(),
    });

    const accumulator = new SpendingsAccumulator(sessions.data, {
      start: dateRange.start!,
      end: dateRange.end!,
    });

    return {
      company,
      acc: accumulator.getTotalSpentPerCurrency(),
      sequences: accumulator.getAccumulatedSpendingsPerDay(),
      metrics: accumulator.getMetrics(),
    };
  },
  component: DashboardPage,
  errorComponent: ErrorPage,
});

import { UseMutationOptions } from "@tanstack/react-query";

import { CreateUser, User } from "@elton/types";

import { createEmployee } from "../requests/employees/create-employee";
import { deleteEmployee } from "../requests/employees/delete-employee";
import { updateEmployee } from "../requests/employees/update-employee";

export const createEmployeeOptions = (
  companyId: string
): UseMutationOptions<CreateUser, Error, CreateUser> => ({
  mutationFn: async (data: CreateUser) => createEmployee(companyId, data),
});

export const updateEmployeeOptions = (): UseMutationOptions<
  CreateUser,
  Error,
  CreateUser
> => ({
  mutationFn: async (data: CreateUser) => updateEmployee(data),
});

export const deleteEmployeeOptions = (): UseMutationOptions<
  User,
  Error,
  string
> => ({
  mutationFn: async (userId: string) => deleteEmployee(userId),
});

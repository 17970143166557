import { IMainNavigationItem } from "@/components/navigation/main-navigation/main-navigation-types";

import { useRole } from "@/context/role-context/use-role";
import { useT } from "@transifex/react";

export const useNavigationItems = (): IMainNavigationItem[] => {
  const t = useT();
  const { isAdmin } = useRole();

  const adminItems = isAdmin
    ? [
        {
          title: t("Admin"),
          href: "https://admin.elton.app",
        },
      ]
    : [];

  return [
    {
      title: t("Companies"),
      href: "/companies",
    },
    ...adminItems,
  ];
};

import { cn } from "@elton/ui/lib/utils";

interface IProps {
  children: React.ReactNode;
  className?: string;
}

export const TopSectionRow: React.FC<IProps> = ({ children, className }) => {
  return (
    <div
      className={cn(
        "flex flex-col md:flex-row md:justify-between space-y-4 md:space-y-0",
        className
      )}
    >
      {children}
    </div>
  );
};

export const TopSectionColumn: React.FC<IProps> = ({ children, className }) => {
  return (
    <div className={cn("flex flex-col w-full md:w-[50%] space-y-1", className)}>
      {children}
    </div>
  );
};

export const TopSectionHeading: React.FC<IProps> = ({
  children,
  className,
}) => {
  return <h2 className={cn("text-lg font-medium", className)}>{children}</h2>;
};

export const TopSectionDescription: React.FC<IProps> = ({
  children,
  className,
}) => {
  return (
    <p className={cn("text-sm text-muted-foreground md:max-w-xl", className)}>
      {children}
    </p>
  );
};

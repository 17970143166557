import { cn } from "@elton/ui/lib/utils";

export const DataPoint: React.FC<{
  className?: string;
  label: string;
  value: string | number;
}> = ({ className, label, value }) => {
  return (
    <div className={cn("text-center", className)}>
      <p className="text-3xl font-semibold">{value}</p>
      <p className="text-xs text-muted-foreground">{label}</p>
    </div>
  );
};

import { SheetTrigger } from "@elton/ui/core";

import { IconButton } from "@/components/buttons/icon-button";
import {
  TopSectionColumn,
  TopSectionDescription,
  TopSectionHeading,
  TopSectionRow,
} from "@/components/sections/top-section";

interface IProps {
  title: string;
  description: string;
  buttonTitle: string;
  onClick: () => void;
  showButton?: boolean;
}

export const DefaultTopSectionContent: React.FC<IProps> = ({
  title,
  description,
  buttonTitle,
  onClick,
  showButton = true,
}) => {
  return (
    <TopSectionRow className="mb-8">
      <TopSectionColumn>
        <TopSectionHeading>{title}</TopSectionHeading>
        <TopSectionDescription>{description}</TopSectionDescription>
      </TopSectionColumn>
      <TopSectionColumn className="md:items-end">
        {showButton && (
          <SheetTrigger asChild onClick={onClick}>
            <IconButton>{buttonTitle}</IconButton>
          </SheetTrigger>
        )}
      </TopSectionColumn>
    </TopSectionRow>
  );
};

import { z } from "zod";

export const DefaultListParamsSchema = z.object({
  offset: z.number().optional().default(0),
  limit: z.number().optional().default(10),
  filter: z.string().optional().default(""),
  sort: z.string().optional().default(""),
});

export type DefaultListParams = z.infer<typeof DefaultListParamsSchema>;

export const getDefaultListLoaderDeps = (opts: {
  search: unknown;
}): DefaultListParams => {
  return DefaultListParamsSchema.parse(opts.search);
};

import { Currency } from "@elton/types";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@elton/ui/core";
import { Sliders } from "@elton/ui/icons";

import { DropdownButton } from "@/components/buttons/dropdown-button";

import { T } from "@transifex/react";

interface IProps {
  currencies: Record<Currency, boolean>;
  onSelect: (currency: Currency) => void;
}

export const CurrencyDropdown: React.FC<IProps> = ({
  currencies,
  onSelect,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownButton>
          <span className="sr-only">
            <T _str="Show/hide currencies" />
          </span>
          <Sliders className="w-4 h-4" />
        </DropdownButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>
          <T _str="Select currencies" />
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {Object.entries(currencies).map(([key, val], i) => {
          return (
            <DropdownMenuCheckboxItem
              key={i}
              onClick={(e) => {
                e.preventDefault();
                onSelect(key as Currency);
              }}
              checked={val}
            >
              <span className="text-sm font-normal uppercase">{key}</span>
            </DropdownMenuCheckboxItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

import { z } from "zod";
import { validatePhoneNumber } from "../../validators/validate-phone-number";

export const userSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(3, { message: "Name is required" }),
  phoneNumber: z.string(),
  reference: z.string().optional(),
  registrationNumber: z.string().optional(),
  department: z
    .object({
      id: z.string().optional(),
      name: z.string().optional(),
    })
    .optional(),
});

export const createUserSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(3, { message: "Name is required" }),
  phoneNumber: z.string().transform(validatePhoneNumber),
  registrationNumber: z.string().optional(),
  reference: z.string().optional(),
  departmentId: z.string().optional(),
});

export type User = z.infer<typeof userSchema>;
export type CreateUser = z.infer<typeof createUserSchema>;

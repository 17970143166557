import { z } from "zod";

const transifexLanguageSchema = z.object({
  code: z.string(),
  localized_name: z.string(),
  name: z.string(),
  rtl: z.boolean(),
});

export type TransifexLanguage = z.infer<typeof transifexLanguageSchema>;

export const supportedLanguages: TransifexLanguage[] = [
  { code: "en", name: "English", localized_name: "English", rtl: false },
  { code: "da", name: "Danish", localized_name: "Dansk", rtl: false },
  { code: "no", name: "Norwegian", localized_name: "Norsk", rtl: false },
  { code: "sv", name: "Swedish", localized_name: "Svenska", rtl: false },
];

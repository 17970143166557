import { useMutation } from "@tanstack/react-query";
import { useRouter } from "@tanstack/react-router";
import { AxiosError } from "axios";

import { useToast } from "@elton/ui/core";

import { updateEmployeeOptions } from "@/api/options/employee-options";
import { useT } from "@transifex/react";

const useUpdateEmployeeMutation = () => {
  const router = useRouter();
  const t = useT();
  const { toast } = useToast();

  return useMutation({
    ...updateEmployeeOptions(),
    onSuccess: () => {
      toast({
        variant: "success",
        description: t("Employee was updated successfully!"),
      });
      router.invalidate();
    },
    onError: (err: AxiosError<{ message?: string }>) => {
      toast({
        variant: "error",
        description: `${t("Employee could not be updated.")} ${err?.response?.data?.message ?? ""}`,
      });
    },
  });
};

export { useUpdateEmployeeMutation };

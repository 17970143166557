import React from "react";
import { ChevronDown } from "lucide-react";
import { cn } from "@ui/lib/utils";

interface IProps {
  title: string;
  icon?: React.ComponentType<{ className?: string }>;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  collapsible?: boolean;
}

export const NavigationLinkRoot: React.FC<IProps> = ({
  title,
  active,
  disabled,
  className,
  icon,
  collapsible,
}) => {
  const Icon = icon;

  return (
    <span
      className={cn(
        "group flex items-center rounded-md px-3 py-2 text-sm text-primary font-medium hover:bg-accent hover:text-accent-foreground",
        active ? "bg-accent" : "transparent",
        disabled &&
          "cursor-not-allowed opacity-50 hover:bg-transparent hover:text-current",
        className
      )}
    >
      {Icon && <Icon className="mr-2 h-4 w-4" />}
      <span>{title}</span>
      {collapsible && (
        <ChevronDown className="transition-all duration-200 transform ml-auto w-4 h-auto group-data-[state=open]:rotate-180" />
      )}
    </span>
  );
};

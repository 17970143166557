import { Link } from "@tanstack/react-router";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@elton/ui/core";
import { LogOut, User } from "@elton/ui/icons";

import { UserProfileAvatar } from "./components/user-profile-avatar";
import { UserProfileDetails } from "./components/user-profile-details";

import { useRole } from "@/context/role-context/use-role";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { T } from "@transifex/react";

export const UserDropdown: React.FC = () => {
  const { user } = useKindeAuth();
  const { role } = useRole();

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger className="flex items-center">
          <UserProfileAvatar user={user} />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>
            <UserProfileDetails user={user} role={role} />
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem asChild>
              <Link to="/settings">
                <User className="mr-2 h-4 w-4" />
                <span>
                  <T _str="Settings" />
                </span>
                <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
              </Link>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuItem asChild>
            <Link to="/logout" preload={false} className="inline-flex w-full">
              <LogOut className="mr-2 h-4 w-4" />
              <span>
                <T _str="Log out" />
              </span>
            </Link>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

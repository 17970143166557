import { useCallback } from "react";
import { Link, Outlet } from "@tanstack/react-router";

import { cn } from "@elton/ui/lib/utils";
import {
  NavigationLinkRoot,
  NavigationLinkSub,
  SidebarNavigation,
} from "@elton/ui/system";

import { SelectCompanyDropdown } from "@/components/dropdowns/select-company-dropdown/select-company-dropdown";
import { LayoutClassNames } from "@/components/layouts/layouts";

import { useActiveSidebarNavigation } from "@/hooks/navigation/use-active-sidebar-navigation-item";
import { useRouterPathname } from "@/hooks/router/use-router-pathname";

import { useSidebarNavigationLinks } from "@/data/navigation/sidebar-navigation-data";

import { Route as SidebarLayoutRoute } from "@/routes/_authenticated/companies/$companyId/_dashboard";
import { ISidebarNavigationItem } from "@/types/navigation/sidebar-navigation";

interface IProps {
  className?: string;
}

export const SidebarLayout: React.FC<IProps> = ({ className }) => {
  const { company, companies } = SidebarLayoutRoute.useLoaderData();

  const pathname = useRouterPathname();
  const sidebarLinks = useSidebarNavigationLinks(company.id!);
  const current = useActiveSidebarNavigation(pathname || "", sidebarLinks);

  const renderRootItem = useCallback(
    (item: ISidebarNavigationItem, index: number) => {
      return (
        <Link key={index} to={item.href} params={{}} disabled={item.disabled}>
          <NavigationLinkRoot
            active={current?.href === item.href}
            disabled={item.disabled}
            title={item.title}
            icon={item.icon}
            collapsible={!!item.subItems?.length}
          />
        </Link>
      );
    },
    [current]
  );

  const renderChildItem = useCallback(
    (item: ISidebarNavigationItem, index: number) => {
      return (
        <Link key={index} to={item.href} params={{}} disabled={item.disabled}>
          <NavigationLinkSub
            active={pathname === item.href}
            disabled={item.disabled}
            title={item.title}
          />
        </Link>
      );
    },
    [pathname]
  );

  return (
    <div className={cn(LayoutClassNames.Container, className)}>
      <aside className={cn(LayoutClassNames.Sidebar, "space-y-4")}>
        <SelectCompanyDropdown companyId={company.id} companies={companies} />
        <SidebarNavigation
          current={current}
          items={sidebarLinks}
          renderItem={renderRootItem}
          renderSubItem={renderChildItem}
        />
      </aside>
      <main
        className={cn(
          LayoutClassNames.Content,
          "space-y-6 h-full min-h-[60vh]"
        )}
      >
        <Outlet />
      </main>
    </div>
  );
};

import { createFileRoute } from "@tanstack/react-router";

import { kindeClient } from "@/api/clients/kinde-client";
import { EltonRoleStoreKey } from "@/context/role-context/role-context";

export const Route = createFileRoute("/logout")({
  beforeLoad: async () => {
    localStorage.removeItem(EltonRoleStoreKey);
    await kindeClient.logout();
  },
  component: () => {},
});

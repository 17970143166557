import { Column } from "@tanstack/react-table";

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@elton/ui/core";
import { Button } from "@elton/ui/core";
import { ChevronDown, Sliders } from "@elton/ui/icons";

import { T } from "@transifex/react";

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Column<any, unknown>[];
}

export const TableColumnsDropdown: React.FC<IProps> = ({ columns }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="ml-auto gap-1">
          <span className="sr-only">
            <T _str="Show/hide columns" />
          </span>
          <Sliders className="w-4 h-4" />
          <ChevronDown className="w-3 h-auto stroke-primary" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>
          <T _str="Select columns" />
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {columns.map((column) => {
          return (
            <DropdownMenuCheckboxItem
              key={column.id}
              className="capitalize text-primary"
              checked={column.getIsVisible()}
              onCheckedChange={(value) => column.toggleVisibility(!!value)}
            >
              {column.id}
            </DropdownMenuCheckboxItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const calculatePageIndex = (limit: number, offset: number): number => {
  if (!limit) {
    return 0;
  }

  return Math.floor(offset / limit);
};

export const parseSortParam = (sort: string): [string, "ASC" | "DESC"] => {
  if (!sort) {
    return ["timestamp", "DESC"];
  }

  return sort.startsWith("-") ? [sort.slice(1), "DESC"] : [sort, "ASC"];
};

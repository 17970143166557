import { tx } from "@transifex/native";

const init = () => {
  tx.init({
    token: import.meta.env.VITE_TRANSIFEX_TOKEN,
  });
};

const TransifexAdapter = {
  init,
};

export default TransifexAdapter;

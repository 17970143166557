import React from "react";
import { Link, useRouter } from "@tanstack/react-router";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@elton/ui/core";

import { TableColumnsDropdown } from "@/components/dropdowns/table-columns-dropdown/table-columns-dropdown";

import { TableInfo } from "../components/table-info";

import { useColumnVisibility } from "./hooks/use-column-visibility";
import { useTableSorting } from "./hooks/use-table-sorting";
import { calculatePageIndex } from "./dynamic-table-utils";
import { TableHeaderRow } from "./header-row";

import { DefaultListParams } from "@/api/params/default-list-params";
import { T } from "@transifex/react";

interface TableProps<TData, TValue, TParams extends DefaultListParams> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  total: number;
  params?: TParams;
  renderToolbar?: () => React.ReactNode;
}

export function DynamicTable<TData, TValue, TParams extends DefaultListParams>({
  renderToolbar,
  columns,
  data,
  total,
  params = {
    offset: 0,
    limit: 10,
    sort: "",
  } as TParams,
}: TableProps<TData, TValue, TParams>) {
  const router = useRouter();
  const sorting = useTableSorting(params.sort);
  const [columnVisibility, setColumnVisibility] = useColumnVisibility();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: sorting.onSortingChange,
    manualSorting: true,
    manualPagination: true,
    rowCount: total,
    defaultColumn: {
      size: 20,
      minSize: 0,
    },
    state: {
      sorting: sorting.state,
      columnVisibility,
      pagination: {
        pageSize: params.limit ?? 10,
        pageIndex: calculatePageIndex(params.limit ?? 10, params.offset ?? 0),
      },
    },
  });

  return (
    <>
      <div className="flex flex-wrap space-between items-end gap-2 relative">
        {renderToolbar && renderToolbar()}
        <TableColumnsDropdown
          columns={table
            .getAllColumns()
            .filter((column) => column.getCanHide())}
        />
      </div>
      <div className="mt-2">
        <div className="rounded-md border max-h-[700px]">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup, i) => (
                <TableHeaderRow headers={headerGroup.headers} key={i} />
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id} className="truncate">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    <T _str="No results." />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className="flex justify-between py-4">
          <TableInfo
            index={table.getState().pagination.pageIndex + 1}
            count={table.getPageCount() || 1}
          />
          <div className="flex items-center justify-end gap-2">
            <Button
              asChild
              variant="outline"
              size="sm"
              disabled={!table.getCanPreviousPage()}
            >
              <Link
                search={{
                  ...params,
                  offset:
                    (table.getState().pagination.pageIndex - 1) *
                    table.getState().pagination.pageSize,
                }}
                params={{}}
                to={router.latestLocation.pathname}
              >
                <T _str="Previous" />
              </Link>
            </Button>
            <Button
              asChild
              variant="outline"
              size="sm"
              disabled={!table.getCanNextPage()}
            >
              <Link
                search={{
                  ...params,
                  offset:
                    (table.getState().pagination.pageIndex + 1) *
                    table.getState().pagination.pageSize,
                }}
                params={{}}
                to={router.latestLocation.pathname}
              >
                <T _str="Next" />
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

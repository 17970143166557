import {
  ChargeSession,
  CompanyMetrics,
  Currency,
  DailySpending,
  SpendingsSequence,
  currencySchema,
  dailySpendingSchema,
} from "@elton/types";
import { format } from "date-fns";

export type DateRange = { start: Date; end: Date };
export type TotalSpentPerDay = Record<Currency, number>;
export type AccumulatedSpendingsPerDate = Record<
  string,
  TotalSpentPerDay & { date: string }
>;

const defaultSpending: TotalSpentPerDay = {
  NOK: 0,
  SEK: 0,
  DKK: 0,
  EUR: 0,
};

const defaultMetrics: CompanyMetrics = {
  chargedKwh: 0,
  sessions: 0,
  chargingUsers: 0,
};

export class SpendingsAccumulator {
  private totalSpentPerCurrency: TotalSpentPerDay = { ...defaultSpending };
  private accumulatedSpendingsPerDate: AccumulatedSpendingsPerDate = {};
  private metrics: CompanyMetrics = { ...defaultMetrics };
  private uniqueUserIds: Set<string> = new Set();

  // TODO: Round up the numbers to 2 decimal places
  constructor(sessions: ChargeSession[], dateRange: DateRange) {
    this.accumulate(sessions, dateRange);
  }

  public getTotalSpentPerCurrency(): TotalSpentPerDay {
    return this.totalSpentPerCurrency;
  }

  public getAccumulatedSpendingsPerDay(): SpendingsSequence[] {
    return Object.values(this.accumulatedSpendingsPerDate).sort((a, b) => {
      return a.date.localeCompare(b.date);
    });
  }

  public getMetrics(): CompanyMetrics {
    return this.metrics;
  }

  private parseSession(session: ChargeSession): DailySpending | null {
    const currency = currencySchema.safeParse(session.price.currency);

    if (!currency.success) {
      return null;
    }

    return dailySpendingSchema.parse({
      date: session.timestamp,
      [currency.data]: session.price.amount,
    });
  }

  /**
   * Applies the metrics from a charge session to the accumulator.
   * @param session - The charge session to apply metrics from.
   */
  private applyMetrics(session: ChargeSession) {
    this.metrics.chargedKwh += session.kwh;
    this.uniqueUserIds.add(session.user);
  }

  /**
   * Given a spending entry, apply it to the total spent for each currency.
   * @param spending - The spending to be applied.
   */
  private applySpendingToTotal(spending: DailySpending) {
    this.totalSpentPerCurrency.NOK += spending.NOK;
    this.totalSpentPerCurrency.SEK += spending.SEK;
    this.totalSpentPerCurrency.DKK += spending.DKK;
    this.totalSpentPerCurrency.EUR += spending.EUR;
  }

  /**
   * Applies a spending to the accumulation of spendings per date.
   * @param spending - The spending to apply.
   */
  private applySpendingToAccumulation(spending: DailySpending) {
    if (!spending.date) {
      return;
    }

    const currentSpending = this.accumulatedSpendingsPerDate[spending.date] ?? {
      ...defaultSpending,
      date: spending.date,
    };

    currentSpending.NOK += spending.NOK;
    currentSpending.SEK += spending.SEK;
    currentSpending.DKK += spending.DKK;
    currentSpending.EUR += spending.EUR;

    this.accumulatedSpendingsPerDate[spending.date] = { ...currentSpending };
  }

  /**
   * Accumulates spendings based on charge sessions and a date range.
   * @param sessions - The charge sessions to accumulate spendings from.
   * @param dateRange - The date range to accumulate spendings within.
   */
  private accumulate(sessions: ChargeSession[], dateRange: DateRange) {
    for (
      let currentDate = dateRange.start;
      currentDate <= dateRange.end;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      const nCurrentDate = format(currentDate, "yyyy-MM-dd");
      this.accumulatedSpendingsPerDate[nCurrentDate] = {
        date: nCurrentDate,
        ...defaultSpending,
      };
    }

    // let currentDate = dateRange.start;
    // while (currentDate <= dateRange.end) {
    //   const dateStamp = format(currentDate, "yyyy-MM-dd");
    //   this.accumulatedSpendingsPerDate[dateStamp] = {
    //     date: dateStamp,
    //     ...defaultSpending,
    //   };
    //   currentDate.setDate(currentDate.getDate() + 1);
    // }

    for (const session of sessions) {
      const spending = this.parseSession(session);
      if (spending) {
        this.applyMetrics(session);
        this.applySpendingToTotal(spending);
        this.applySpendingToAccumulation(spending);
      }
    }

    this.metrics.sessions = sessions.length;
    this.metrics.chargingUsers = this.uniqueUserIds.size;
  }
}

import { Button } from "@elton/ui/core";
import { cn } from "@elton/ui/lib/utils";

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { T } from "@transifex/react";

interface IProps {
  className?: string;
}

export const RedirectToKinde: React.FC<IProps> = ({ className }) => {
  const { login } = useKindeAuth();

  return (
    <div className={cn("flex items-center", className)}>
      <div className="mx-auto flex flex-col justify-center space-y-4 sm:w-[450px]">
        {/* <div className="flex flex-col space-y-2 text-center">
          <p className="text-sm text-muted-foreground">
            <T _str="Click continue to sign in with your email" />
          </p>
        </div> */}
        <Button onClick={() => login()} variant="default">
          <T _str="Continue with email" />
        </Button>
        <SigninDescription />
      </div>
    </div>
  );
};

const SigninDescription = () => (
  <p className="px-2 text-center text-xs text-muted-foreground">
    <T _str="By clicking continue, you agree to our" />{" "}
    <a
      href="https://elton.app/legal/terms-of-service"
      target="_blank"
      rel="noopener noreferrer"
      className="hover:underline underline-offset-4 hover:text-primary"
    >
      <T _str="Terms of Service" />
    </a>{" "}
    <T _str="and" />{" "}
    <a
      href="https://elton.app/legal/privacy"
      target="_blank"
      rel="noopener noreferrer"
      className="hover:underline underline-offset-4 hover:text-primary"
    >
      <T _str="Privacy Policy" />
    </a>
    .
  </p>
);

export const formatDollarsToCents = (value: number): number => {
  let valueString = (value + "").replace(/[^\d.-]/g, "");

  if (value && valueString.includes(".")) {
    valueString = valueString.substring(0, valueString.indexOf(".") + 3);
  }

  return valueString ? Math.round(parseFloat(valueString) * 100) : 0;
};

export const formatCentsToDollars = (value: number): number => {
  let valueString = (value + "").replace(/[^\d.-]/g, "");

  value = parseFloat(valueString);

  return value ? value / 100 : 0;
};

import { CreateDepartment } from "@elton/types";

export const defaultDepartment: CreateDepartment = {
  id: "",
  name: "",
};

export const defaultDepartments: CreateDepartment[] = [
  {
    id: "1",
    name: "Tech",
  },
  {
    id: "2",
    name: "Operations",
  },
  {
    id: "3",
    name: "Marketing",
  },
  {
    id: "4",
    name: "External",
  },
];

import { CreateDepartment } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";

export const createDepartment = async (
  companyId: string,
  data: CreateDepartment
) => {
  await delay(2000);
  const res = await getServicesClient().post(
    `/companies/${companyId}/departments`,
    {
      name: data.name,
    }
  );

  return res.data;
};

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

import { useContext } from "react";

import { LocalizationProviderContext } from "./localization-context";

export const useLocalization = () => {
  const context = useContext(LocalizationProviderContext);

  if (context === undefined) {
    throw new Error(
      "useLocalization must be used within a LocalizationProvider"
    );
  }

  return context;
};

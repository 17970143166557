import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@elton/ui/core";

import { T } from "@transifex/react";

interface IProps {
  title: string;
  description: string;
  onContinue: () => void;
}

export const DefaultDialogContent: React.FC<IProps> = ({
  title,
  description,
  onContinue,
}) => {
  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>{title}</AlertDialogTitle>
        <AlertDialogDescription>{description}</AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel>
          <T _str="Cancel" />
        </AlertDialogCancel>
        <AlertDialogAction onClick={onContinue}>
          <T _str="Continue" />
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};

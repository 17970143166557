import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";

import { ErrorPage } from "@/pages/error-page/error-page";

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

interface IRootRouteContext {
  auth: ReturnType<typeof useKindeAuth>;
}

export const Route = createRootRouteWithContext<IRootRouteContext>()({
  component: RootComponent,
  errorComponent: ErrorPage,
});

function RootComponent() {
  return (
    <>
      <Outlet />
    </>
  );
}

import React from "react";
import { Link } from "@tanstack/react-router";
import { format } from "date-fns";

import { ListCompany } from "@elton/types";
import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@elton/ui/core";
import { Check, ChevronsUpDown } from "@elton/ui/icons";
import { cn } from "@elton/ui/lib/utils";

import { T, useT } from "@transifex/react";

interface IProps {
  companyId?: string;
  companies: ListCompany[];
}

export const SelectCompanyDropdown: React.FC<IProps> = ({
  companyId,
  companies,
}) => {
  const t = useT();
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className="w-[200px] justify-between"
        >
          <span className="truncate ellipsis">
            {companyId
              ? companies?.find((company) => company.id === companyId)?.name
              : t("Select company") + ".."}
          </span>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search companies..." />
          <CommandList>
            <CommandEmpty>
              <T _str="No company found." />
            </CommandEmpty>
            <CommandGroup heading="Your companies">
              {companies.map((company) => {
                return (
                  <CommandItem key={company.id} asChild>
                    <Link
                      to="/companies/$companyId/dashboard"
                      params={{ companyId: company.id! }}
                      onClick={() => setOpen(false)}
                      search={{ date: format(new Date(), "yyyy-MM-dd") }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          companyId === company.id ? "opacity-100" : "opacity-0"
                        )}
                      />
                      <span>{company.name}</span>
                    </Link>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

import { ListCompany } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";
import { DefaultListParams } from "@/api/params/default-list-params";

export interface IListCompanyData {
  data: ListCompany[];
  total: number;
}

export const getCompanies = async (params?: DefaultListParams) => {
  const response = await getServicesClient().get<IListCompanyData>(
    `/companies`,
    { params }
  );

  return response.data;
};

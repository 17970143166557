import { useEffect } from "react";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";

import { CreateDepartment } from "@elton/types";

import { TFormAction } from "../form-types";

interface IProps {
  data: CreateDepartment;
  onSubmit: (value: CreateDepartment, action: TFormAction) => void;
}

export const useDepartmentForm = ({ data, onSubmit }: IProps) => {
  const form = useForm({
    defaultValues: data,
    validatorAdapter: zodValidator(),
    onSubmit: (values) => {
      return onSubmit(values.value, data.id ? "update" : "create");
    },
  });

  useEffect(() => {
    form.setFieldValue("id", data.id);
    form.setFieldValue("name", data.name);
  }, [data, form]);

  return form;
};

import { Button } from "@elton/ui/core";

import { DataList } from "@/components/lists/data-list/data-list";

import { T } from "@transifex/react";

export type CompletionStatus = "COMPLETED" | "INCOMPLETE";

interface IProps {
  onClick: () => void;
  title: string;
  data: { title: string; value: string; disabled?: boolean }[];
  showButton?: boolean;
}

export const DataListSection: React.FC<IProps> = ({
  onClick,
  title,
  data,
  showButton = false,
}) => {
  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-2">
          <h3 className="text-md font-medium">{title}</h3>
        </div>
        {showButton && (
          <Button variant="secondary" onClick={onClick}>
            <T _str="Edit" />
          </Button>
        )}
      </div>
      <DataList data={data} />
    </div>
  );
};

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@elton/ui/core";
import { ClipboardCopy, MoreHorizontal } from "@elton/ui/icons";

import { T } from "@transifex/react";

interface IProps {
  companyId: string;
  onDelete: (companyId: string) => void;
  onCopyId: (companyId: string) => void;
  className?: string;
}

export const CompanyDropdownActions: React.FC<IProps> = ({
  companyId,
  onCopyId,
  className,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className={className}>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">
              <T _str="Open menu" />
            </span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>
          <T _str="Actions" />
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem className="gap-2" onClick={() => onCopyId(companyId)}>
          <ClipboardCopy className="w-3 h-auto" />
          <span>
            <T _str="Copy ID" />
          </span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

import { CreateUser } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";

export const updateEmployee = async (data: CreateUser) => {
  const res = await getServicesClient().put(`/users/${data.id}`, {
    name: data.name,
    registrationNumber: data.registrationNumber,
    reference: data.reference,
    departmentId: data.departmentId,
  });

  return res.data;
};

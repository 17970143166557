import { Link } from "@tanstack/react-router";

import { cn } from "@elton/ui/lib/utils";

import { EltonLogoHorizontalIcon } from "@/components/icons/elton/elton-logo-horizontal";

interface IProps {
  className?: string;
}

export const HomeLink: React.FC<IProps> = ({ className }) => {
  return (
    <Link className={cn("flex gap-2", className)} to="/">
      <EltonLogoHorizontalIcon className="w-20 md:w-24 h-auto" />
    </Link>
  );
};

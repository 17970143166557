import { CreateCompany } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";

export const updateCompany = async (companyId: string, data: CreateCompany) => {
  const res = await getServicesClient().put(`/companies/${companyId}`, {
    name: data.name,
    organizationNumber: data.organizationNumber,
    address: data.address,
    postalCode: data.postalCode,
    city: data.city,
    country: data.country,
    contactName: data.contactName,
    contactPhone: data.contactPhone,
    invoiceEmail: data.invoiceEmail,
    // invoicingReferences: company.invoicingReferences, // TODO
    // billingStrategy: company.billingStrategy, // TODO
    discountPercentage: data.discountPercentage,
  });

  if (data.paymentPlanId) {
    await getServicesClient().put(`/companies/${companyId}/plan`, {
      basePlanId: data.paymentPlanId,
    });
  }

  return res.data;
};

import { useCallback } from "react";
import { useNavigate } from "@tanstack/react-router";
import debounce from "debounce";

export const useSessionSearchParamsUpdater = (initialPath: string) => {
  const navigate = useNavigate({ from: initialPath });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdateSearch = useCallback(
    debounce((filterValue: string) => {
      navigate({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        search: (prev: any) => {
          /**
           * When the filter value changes, we need to reset the offset and limit
           */
          if (prev.filter !== filterValue) {
            return {
              ...prev,
              filter: filterValue,
              offset: 0,
              limit: 10,
            };
          }

          return {
            ...prev,
            filter: filterValue,
          };
        },
      });
    }, 500),
    [navigate]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeDateRange = useCallback(
    // Debounces here prevents full re-rendering of the page, so should be left as-is. Not sure why that is
    debounce(
      (dateRange: {
        dateFrom?: Date | undefined;
        dateTo?: Date | undefined;
      }) => {
        navigate({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          search: (prev: any) => {
            return {
              ...prev,
              dateFrom: dateRange?.dateFrom?.toISOString() ?? undefined,
              dateTo: dateRange?.dateTo?.toISOString() ?? undefined,
            };
          },
        });
      },
      0
    ),
    [navigate]
  );

  return {
    updateSearchFilter: handleUpdateSearch,
    updateDateRange: handleChangeDateRange,
  };
};

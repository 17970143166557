import { useMemo } from "react";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@elton/ui/core";
import { HelpCircle } from "@elton/ui/icons";
import { cn } from "@elton/ui/lib/utils";

import { T } from "@transifex/react";

export interface IProps {
  title: string;
  description: string;
  value: string;
  progress?: number;
  icon?: React.ReactNode;
  className?: string;
}

export const MetricsCard: React.FC<IProps> = ({
  title,
  description,
  value,
  progress,
  icon,
  className,
}) => {
  const nProgress = useMemo(() => {
    if (!progress) {
      return null;
    }

    return {
      prefix: progress > 0 ? "+" : "",
      value: progress,
    };
  }, [progress]);

  return (
    <Card className={className}>
      <CardHeader className="pt-6 px-6 flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="tracking-tight text-sm font-medium inline-flex items-center gap-1">
          <span>{title}</span>
          {description && (
            <HoverCard>
              <HoverCardTrigger>
                <HelpCircle className="w-3 h-auto text-current" />
              </HoverCardTrigger>
              <HoverCardContent className="text-sm font-normal">
                {description}
              </HoverCardContent>
            </HoverCard>
          )}
        </CardTitle>

        {icon && icon}
      </CardHeader>
      <CardContent className="gap-1 pt-0">
        <div className="text-2xl font-bold">{value}</div>
        {nProgress && (
          <p className="text-xs text-muted-foreground">
            <span
              className={cn(
                nProgress.prefix === "+" ? "text-green-700" : "text-red-700"
              )}
            >{`${nProgress.prefix}${nProgress.value}%`}</span>{" "}
            <T _str="from last month" />
          </p>
        )}
      </CardContent>
    </Card>
  );
};

import { createFileRoute, redirect } from "@tanstack/react-router";
import { format } from "date-fns";

import { getCompany } from "@/api/requests/companies/get-company";

export const Route = createFileRoute("/_authenticated/companies/$companyId/")({
  beforeLoad: async ({ params: { companyId } }) => {
    throw redirect({
      to: "/companies/$companyId/dashboard",
      params: { companyId },
      search: { date: format(new Date(), "yyyy-MM-dd") },
    });
  },
  loader: async ({ params: { companyId } }) => {
    const company = await getCompany(companyId);

    return { company };
  },
});

import { useMutation } from "@tanstack/react-query";
import { useRouter } from "@tanstack/react-router";
import { AxiosError } from "axios";

import { useToast } from "@elton/ui/core";

import { updateDepartmentOptions } from "@/api/options/department-options";
import { useT } from "@transifex/react";

export const useUpdateDepartmentMutation = () => {
  const t = useT();
  const router = useRouter();
  const { toast } = useToast();

  return useMutation({
    ...updateDepartmentOptions(),
    onSuccess: (data) => {
      toast({
        variant: "success",
        description: t("Department {name} was updated successfully!", {
          name: data.name,
        }),
      });
      router.invalidate();
    },
    onError: (err: AxiosError<{ message?: string }>) => {
      toast({
        variant: "error",
        description: `${t("Department could not be updated.")} ${err?.response?.data?.message ?? ""}`,
      });
    },
  });
};

import { CreateDepartment } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";

export const updateDepartment = async (data: CreateDepartment) => {
  const res = await getServicesClient().put(`/departments/${data.id}`, {
    name: data.name,
  });

  return res.data;
};

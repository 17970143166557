import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { ChargeSession, List } from "@elton/types";

import { SessionsPage } from "@/pages/sessions-page/sessions-page";

import {
  dateRangeParamsSchema,
  getDateRangeLoaderDeps,
} from "@/api/params/date-range-params";
import { getChargeSessions } from "@/api/requests/chargings/get-charge-sessions";

const sessionsSchema = dateRangeParamsSchema.extend({
  sort: z.string().optional().default("-timestamp"),
});

export type SessionsParams = z.infer<typeof sessionsSchema>;

export const Route = createFileRoute(
  "/_authenticated/companies/$companyId/_dashboard/sessions"
)({
  component: SessionsPage,
  validateSearch: sessionsSchema,
  loaderDeps: getDateRangeLoaderDeps,
  loader: async ({
    params,
    deps,
  }): Promise<{
    sessions: List<ChargeSession>;
    companyId: string;
  }> => {
    return {
      companyId: params.companyId,
      sessions: await getChargeSessions(params.companyId, deps),
    };
  },
});

import { z } from "zod";

export const accountSchema = z.object({
  id: z.string().optional(),
  firstName: z.string().min(1, { message: "First name is required" }),
  lastName: z.string().min(1, { message: "Last name is required" }),
  email: z.string().email({ message: "Email is required" }),
});

export type Account = z.infer<typeof accountSchema>;

import { Account } from "../../types";

export const defaultAccount: Account = {
  id: "",
  email: "",
  firstName: "",
  lastName: "",
};

export const defaultAccounts: Account[] = [
  {
    id: "1",
    firstName: "John",
    lastName: "Smith",
    email: "john.smith@elton.app",
  },
  {
    id: "2",
    firstName: "Jane",
    lastName: "Doe",
    email: "jane.doe@elton.app",
  },
];

import { useMemo } from "react";
import { z } from "zod";

import { DateRangeParams } from "@/api/params/date-range-params";

type Params = Pick<DateRangeParams, "dateFrom" | "dateTo">;

export const useDateRange = (params: Params) => {
  return useMemo(() => {
    const dateSchema = z.coerce.date().optional();

    const dateFromRes = dateSchema.safeParse(params.dateFrom);
    const dateToRes = dateSchema.safeParse(params.dateTo);
    return {
      range: {
        from: dateFromRes.data,
        to: dateToRes.data,
      },
    };
  }, [params.dateFrom, params.dateTo]);
};

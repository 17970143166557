import { Avatar, AvatarFallback, AvatarImage } from "@elton/ui/core";

import { KindeUser } from "@kinde-oss/kinde-auth-pkce-js";

export const UserProfileAvatar: React.FC<{ user?: KindeUser }> = ({ user }) => (
  <Avatar className="w-8 h-8">
    {user && user.picture && (
      <AvatarImage className="bg-muted" src={user.picture} />
    )}
    <AvatarFallback className="bg-muted" />
  </Avatar>
);

import { CreateUser } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";

export const createEmployee = async (companyId: string, data: CreateUser) => {
  const res = await getServicesClient().post(`/companies/${companyId}/users`, {
    name: data.name,
    phoneNumber: data.phoneNumber,
    registrationNumber: data.registrationNumber,
    reference: data.reference,
    departmentId: data.departmentId,
  });

  return res.data;
};

import { useRouterState } from "@tanstack/react-router";

/**
 * Returns the current pathname from the router location.
 * @returns The current pathname or null if not available.
 */
export const useRouterPathname = (): string | null => {
  const router = useRouterState();

  return router.location.pathname || null;
};

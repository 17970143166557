import { User } from "@elton/types";
import {
  AlertDialogTrigger,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  SheetTrigger,
} from "@elton/ui/core";
import { Edit, MoreHorizontal, Trash } from "@elton/ui/icons";

import { T } from "@transifex/react";

interface IProps {
  data: User;
  onEditUser: (user: User) => void;
  onDeleteUser: (user: User) => void;
  onCopyUserId: (userId: string) => void;
  className?: string;
}

export const UserDropdownActions: React.FC<IProps> = ({
  data,
  onEditUser,
  onDeleteUser,
  onCopyUserId,
  className,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className={className}>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">
              <T _str="Open menu" />
            </span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>
          <T _str="Actions" />
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="gap-2"
          onClick={() => onCopyUserId(data.id!)}
        >
          <Edit className="w-3 h-auto" />
          <span>
            <T _str="Copy ID" />
          </span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <SheetTrigger asChild>
          <DropdownMenuItem className="gap-2" onClick={() => onEditUser(data)}>
            <Edit className="w-3 h-auto" />
            <span>
              <T _str="Edit user" />
            </span>
          </DropdownMenuItem>
        </SheetTrigger>
        <AlertDialogTrigger asChild>
          <DropdownMenuItem
            className="text-destructive gap-2"
            onClick={() => onDeleteUser(data)}
          >
            <Trash className="w-3 h-auto" />
            <span>
              <T _str="Delete user" />
            </span>
          </DropdownMenuItem>
        </AlertDialogTrigger>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

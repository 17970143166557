import React from "react";

import { IProps } from "../container/sidebar-navigation";
import { cn } from "@ui/lib/utils";

export const NavigationContentSmall: React.FC<IProps<any>> = ({
  className,
  items,
  current,
  renderItem,
  renderSubItem,
}) => {
  return (
    <div className={cn("space-y-2", className)}>
      <nav className="flex overflow-x-auto scrollbar-hide gap-2">
        {items.map((item, index: number) => {
          return renderItem(item, index);
        })}
      </nav>
      {current && current.subItems && (
        <nav className="flex gap-1">
          {current.subItems.map((subItem: any, index: number) => {
            return renderSubItem(subItem, index);
          })}
        </nav>
      )}
    </div>
  );
};

import { ChargeSession, List } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";
import { DateRangeParams } from "@/api/params/date-range-params";

export const getChargeSessions = async (
  companyId: string,
  params?: DateRangeParams
) => {
  const res = await getServicesClient().post<List<ChargeSession>>(
    `/companies/${companyId}/sessions`,
    { ...params }
  );

  return res.data;
};

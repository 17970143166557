import { useState } from "react";

import { CreateCompany } from "@elton/types";
import { Sheet, SheetContent } from "@elton/ui/core";

import {
  CompanyPartialForm,
  FormRenderState,
} from "@/components/forms/company-form/partial/company-partial-form";
import {
  TopSectionColumn,
  TopSectionDescription,
  TopSectionHeading,
  TopSectionRow,
} from "@/components/sections/top-section";

import { DataListSection } from "./components/data-list-section";

import { useUpdateCompanyMutation } from "@/api/mutations/company/update-company-mutation";
import { useRole } from "@/context/role-context/use-role";
import { Route as CompanySettingsRoute } from "@/routes/_authenticated/companies/$companyId/_dashboard/settings";
import { T, useT } from "@transifex/react";

export const CompanyDetailsPage: React.FC = () => {
  const t = useT();
  const { isAdmin } = useRole();

  const loaderData = CompanySettingsRoute.useLoaderData();
  const updateCompany = useUpdateCompanyMutation();

  const [formOpen, setFormOpen] = useState(false);
  const [formState, setFormState] = useState<FormRenderState | null>(null);

  const handleChangeFormRenderState = (state: FormRenderState) => () => {
    setFormState(state);
    setFormOpen(true);
  };

  const handleUpdateCompany = async (data: CreateCompany) => {
    await updateCompany.mutateAsync({
      companyId: loaderData.companyId,
      data,
    });
    setFormOpen(false);
  };

  return (
    <>
      <Sheet open={formOpen} onOpenChange={setFormOpen}>
        <SheetContent>
          <CompanyPartialForm
            loading={updateCompany.isPending}
            state={formState}
            data={{
              ...loaderData.company,
              paymentPlanId: loaderData.companyPlan?.basePlanId,
            }}
            paymentPlans={loaderData.paymentPlans.data}
            onUpdate={handleUpdateCompany}
          />
        </SheetContent>
      </Sheet>
      <TopSectionRow className="mb-16">
        <TopSectionColumn>
          <TopSectionHeading>
            <T _str="Company details" />
          </TopSectionHeading>
          <TopSectionDescription>
            <T _str="Here you can access company details and make necessary updates to ensure all information remains current and accurate." />
          </TopSectionDescription>
        </TopSectionColumn>
      </TopSectionRow>
      <div className="space-y-16">
        <DataListSection
          title={t("Key company details")}
          onClick={handleChangeFormRenderState("KEY")}
          showButton={isAdmin}
          data={[
            {
              title: t("Company Name"),
              value: loaderData.company.name,
            },
            {
              title: t("Org. num"),
              value: loaderData.company.organizationNumber,
            },
            {
              title: t("Customer ID"),
              value: loaderData.company.id!,
              disabled: true,
            },
          ]}
        />
        <DataListSection
          title={t("Contact information")}
          onClick={handleChangeFormRenderState("CONTACT")}
          showButton={isAdmin}
          data={[
            {
              title: t("Contact name"),
              value: loaderData.company.contactName,
            },
            {
              title: t("Contact phone"),
              value: loaderData.company.contactPhone,
            },
            {
              title: t("Address"),
              value: loaderData.company.address,
            },
            {
              title: t("Postal code"),
              value: loaderData.company.postalCode,
            },
            {
              title: t("City"),
              value: loaderData.company.city,
            },
            {
              title: t("Country"),
              value: loaderData.company.country,
            },
          ]}
        />
        <DataListSection
          title={t("Payment & Invoicing")}
          onClick={handleChangeFormRenderState("PAYMENT")}
          showButton={isAdmin}
          data={[
            {
              title: t("Invoice email"),
              value: loaderData.company.invoiceEmail,
            },
            {
              title: t("Discount"),
              value: `${loaderData.company.discountPercentage ?? "0"}%`,
            },
            {
              title: t("Payment plan"),
              value: loaderData.companyPlan?.name ?? "",
            },
          ]}
        />
      </div>
    </>
  );
};

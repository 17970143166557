import { z } from "zod";

export const chargeSessionSchema = z.object({
  id: z.string().optional(),
  user: z.string(),
  station: z.string(),
  kwh: z.number(),
  price: z.object({
    amount: z.number().min(0).catch(0), // TODO: Not sure about these defaults
    currency: z.string(), // TODO: Of enum Currency ??
  }),
  timestamp: z.string(),
});

export type ChargeSession = z.infer<typeof chargeSessionSchema>;

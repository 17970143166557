import React from "react";

import { Separator } from "@elton/ui/core";
import { Facebook, Instagram, Linkedin } from "@elton/ui/icons";
import { cn } from "@elton/ui/lib/utils";

import { EltonLogoSymbolIcon } from "@/components/icons/elton/elton-logo-symbol";

import { LayoutClassNames } from "../layouts";

import { FooterNavigationMenu } from "./components/footer-navigation-menu";

import { T, useT } from "@transifex/react";

interface IProps {
  className?: string;
}

export const Footer: React.FC<IProps> = ({ className }) => {
  return (
    <footer
      className={cn(
        "pt-10 pb-10 space-y-8 md:space-y-16 px-2 md:px-8",
        className
      )}
    >
      <Separator className="opacity-50" />

      <div className={LayoutClassNames.Container}>
        <div
          className={cn(
            LayoutClassNames.SidebarSpaced,
            LayoutClassNames.Content,
            "space-y-12"
          )}
        >
          <div className="grid grid-cols-12 gap-8 md:gap-2">
            <CompanyNav className="col-span-6 sm:col-span-4 md:col-span-3" />
            <HelpNav className="col-span-6 sm:col-span-4 md:col-span-3" />
            <SocialNav className="col-span-full sm:col-span-full md:col-span-6" />
          </div>
          <div className="flex gap-4 items-center">
            <EltonLogoSymbolIcon className="w-12 h-auto" />
            <div>
              <h3 className="text-md font-medium text-foreground leading-relaxed">
                Elton
              </h3>
              <p className="text-sm text-muted-foreground leading-tight">
                <T _str="@ 2024 Elton Mobility AS. All rights reserved" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const CompanyNav = ({ className }: { className?: string }) => {
  const t = useT();
  return (
    <FooterNavigationMenu
      className={className}
      title={t("Company")}
      items={[
        { title: t("News"), href: "https://elton.app/news" },
        { title: t("Privacy Policy"), href: "https://elton.app/legal/privacy" },
        {
          title: t("Terms of Service"),
          href: "https://elton.app/legal/terms-of-service",
        },
      ]}
    />
  );
};

const HelpNav = ({ className }: { className?: string }) => {
  const t = useT();
  return (
    <FooterNavigationMenu
      className={className}
      title={t("Help")}
      items={[
        { title: t("FAQ"), href: "" },
        {
          title: t("Support"),
          href: "https://support.elton.app/nb-NO/support/home",
        },
      ]}
    />
  );
};

const SocialNav = ({ className }: { className?: string }) => {
  return (
    <nav className={cn("space-y-4", className)}>
      <h3 className="text-lg font-semibold">
        <T _str="Social" />
      </h3>
      <ul className="gap-4 flex items-center">
        <a
          href="https://www.facebook.com/eltonapp/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:opacity-75 transition-opacity"
        >
          <Facebook className="w-6 h-auto text-muted-foreground" />
        </a>
        <a
          href="https://www.instagram.com/eltonnorge"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:opacity-75 transition-opacity"
        >
          <Instagram className="w-6 h-auto text-muted-foreground" />
        </a>
        <a
          href="https://www.linkedin.com/company/eltonapp/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:opacity-75 transition-opacity"
        >
          <Linkedin className="w-6 h-auto text-muted-foreground" />
        </a>
      </ul>
    </nav>
  );
};

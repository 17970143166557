import React from "react";

import { Button } from "@elton/ui/core";

export const CreateDepartmentButton: React.FC<{
  disabled?: boolean;
  children: React.ReactNode;
}> = ({ disabled, children }) => {
  return (
    <Button asChild variant="secondary" size="sm" disabled={disabled}>
      {children}
    </Button>
  );
};

import React from "react";
import { Link } from "@tanstack/react-router";

import {
  ListItem,
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@elton/ui/core";
import { cn } from "@elton/ui/lib/utils";

import { MainNavigationProps } from "../main-navigation-types";

export const MainNavigationLarge: React.FC<MainNavigationProps> = ({
  className,
  items,
}) => {
  return (
    <>
      {items.map((item, index) => (
        <NavigationMenu className={cn("text-primary", className)} key={index}>
          <NavigationMenuList>
            {item.items ? (
              <ExpandableNav title={item.title} items={item.items} />
            ) : (
              <DefaultNav title={item.title} href={item.href!} />
            )}
          </NavigationMenuList>
        </NavigationMenu>
      ))}
    </>
  );
};

const DefaultNav: React.FC<{ title: string; href: string }> = ({
  title,
  href,
}) => {
  return (
    <NavigationMenuItem className="cursor-pointer">
      <Link
        className={cn("inline-flex gap-2", navigationMenuTriggerStyle())}
        to={href}
      >
        <span>{title}</span>
      </Link>
    </NavigationMenuItem>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ExpandableNav: React.FC<{ title: string; items: any[] }> = ({
  title,
  items,
}) => {
  return (
    <NavigationMenuItem>
      <NavigationMenuTrigger>{title}</NavigationMenuTrigger>
      <NavigationMenuContent className="relative">
        <ul className="grid gap-1 p-2 md:w-[400px] lg:w-[400px]">
          {items.map((item, index) => (
            <ListItem
              key={index}
              href={item.href}
              title={item.title}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.description}
            </ListItem>
          ))}
        </ul>
      </NavigationMenuContent>
    </NavigationMenuItem>
  );
};

import { UseMutationOptions } from "@tanstack/react-query";

import { Account } from "@elton/types";

import { createAccount } from "../requests/accounts/create-account";
import { deleteAccount } from "../requests/accounts/delete-account";
import { updateAccount } from "../requests/accounts/update-account";

export const createAccountOptions = (
  companyId: string
): UseMutationOptions<Account, Error, Account> => ({
  mutationFn: async (data: Account) => createAccount(companyId, data),
});

export const updateAccountOptions = (): UseMutationOptions<
  Account,
  Error,
  Account
> => ({
  mutationFn: async (data: Account) => updateAccount(data),
});

export const deleteAccountOptions = (
  companyId: string
): UseMutationOptions<Account, Error, string> => ({
  mutationFn: async (accountId: string) => deleteAccount(companyId, accountId),
});

import { z } from "zod";

export const departmentSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(3, { message: "Name is required" }),
});

export const listDepartmentSchema = z.object({
  id: z.string(),
  name: z.string(),
  companyId: z.string(),
  userCount: z.number(),
});

export type CreateDepartment = z.infer<typeof departmentSchema>;
export type ListDepartment = z.infer<typeof listDepartmentSchema>;

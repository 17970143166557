import { useEffect, useState } from "react";

import { CreateDepartment } from "@elton/types";
import { defaultDepartment } from "@elton/types/mocks";
import { AlertDialog, SearchInput, Sheet, SheetContent } from "@elton/ui/core";
import { useToast } from "@elton/ui/core";

import { DepartmentForm } from "@/components/forms/department-form/department-form";
import { useDepartmentColumns } from "@/components/tables/columns/department-columns";
import { DynamicTable } from "@/components/tables/dynamic-table/dynamic-table";

import { useDebouncedSearchNavigation } from "@/hooks/search/use-search-navigation";

import { DefaultDialogContent } from "../_common/default-alert-content";
import { DefaultTopSectionContent } from "../_common/default-top-section-content";

import { useCreateDepartmentMutation } from "@/api/mutations/department/create-department-mutation";
import { useDeleteDepartmentMutation } from "@/api/mutations/department/delete-department-mutation";
import { useUpdateDepartmentMutation } from "@/api/mutations/department/update-department-mutation";
import {
  DepartmentsSearchParams,
  Route as DepartmentsRoute,
} from "@/routes/_authenticated/companies/$companyId/_dashboard/departments";
import { useT } from "@transifex/react";

export const DepartmentsPage: React.FC = () => {
  const t = useT();
  const { toast } = useToast();
  const loaderData = DepartmentsRoute.useLoaderData();
  const searchParams = DepartmentsRoute.useSearch<DepartmentsSearchParams>();
  const { updateSearchFilter, removeOpenParameter } =
    useDebouncedSearchNavigation(DepartmentsRoute.fullPath);

  const [formOpen, setFormOpen] = useState(
    typeof searchParams.open !== "undefined"
  );
  const [formData, setFormData] = useState<CreateDepartment>(defaultDepartment);
  const [selected, setSelected] = useState<CreateDepartment | null>(null);

  const createDepartment = useCreateDepartmentMutation(loaderData.companyId);
  const updateDepartment = useUpdateDepartmentMutation();
  const deleteDepartment = useDeleteDepartmentMutation();

  const columns = useDepartmentColumns({
    onUpdate: (value: CreateDepartment) => setFormData(value),
    onDelete: (value: CreateDepartment) => setSelected(value),
    onCopyId: (value: string) => {
      navigator.clipboard.writeText(value);
      toast({
        variant: "info",
        description: t("ID copied to the clipboard."),
      });
    },
  });

  useEffect(() => {
    if (typeof searchParams.open !== "undefined") {
      removeOpenParameter();
    }
  }, [searchParams.open, removeOpenParameter]);

  const handleProceedWithDeletion = () => {
    if (selected) {
      deleteDepartment.mutate(selected.id!);
    }
  };

  const handleShowCreateForm = () => {
    setFormData(defaultDepartment);
  };

  return (
    <AlertDialog>
      <Sheet open={formOpen} onOpenChange={setFormOpen}>
        <DefaultTopSectionContent
          title={t("Departments")}
          description={t(
            "Manage your company's departments. Here you can add, edit, delete departments."
          )}
          buttonTitle={t("Create department")}
          onClick={handleShowCreateForm}
        />
        <div className="relative w-full">
          <DynamicTable
            renderToolbar={() => {
              return (
                <>
                  <SearchInput
                    defaultValue={searchParams.filter}
                    containerClassName="w-full sm:max-w-96"
                    placeholder={t("Search") + ".."}
                    onChange={updateSearchFilter}
                  />
                </>
              );
            }}
            columns={columns}
            data={loaderData.departments.data}
            total={loaderData.departments.total}
            params={searchParams}
          />
        </div>
        <SheetContent className="space-y-4">
          <DepartmentForm
            loading={createDepartment.isPending || updateDepartment.isPending}
            data={formData}
            onCreate={async (data) => {
              await createDepartment.mutateAsync(data);
              setFormOpen(false);
            }}
            onUpdate={async (data) => {
              await updateDepartment.mutateAsync(data);
              setFormOpen(false);
            }}
          />
        </SheetContent>
      </Sheet>
      <DefaultDialogContent
        onContinue={handleProceedWithDeletion}
        title={t("Do you want to proceed?")}
        description={t(
          "This action cannot be undone. This will permanently delete the department {name}",
          { name: selected?.name }
        )}
      />
    </AlertDialog>
  );
};

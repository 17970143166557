import { Badge } from "@elton/ui/core";

import { EltonRole } from "@/context/role-context/role-context";
import { KindeUser } from "@kinde-oss/kinde-auth-pkce-js";

export const UserProfileDetails: React.FC<{
  user?: KindeUser;
  role: EltonRole;
}> = ({ user, role }) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="inline-flex justify-between items-center gap-1">
        <span className="truncate">
          {`${user?.given_name} ${user?.family_name}`}
        </span>
        {role === "superadmin" && <Badge variant="outline">ADMIN</Badge>}
      </div>
      <span className="truncate text-xs text-secondary-foreground font-normal">
        {user?.email ?? ""}
      </span>
    </div>
  );
};

import { Account, List } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";
import { DefaultListParams } from "@/api/params/default-list-params";

export const getAccounts = async (
  companyId: string,
  params?: DefaultListParams
) => {
  const res = await getServicesClient().get<List<Account>>(
    `/companies/${companyId}/accounts`,
    { params }
  );

  return res.data;
};

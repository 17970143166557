import { FormState } from "@tanstack/react-form";

import { CreateDepartment, departmentSchema } from "@elton/types";
import {
  SheetClose,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@elton/ui/core";

import { LoadingButton } from "@/components/buttons/loading-button";
import { InputField } from "@/components/fields/input-field";

import { TFormAction } from "../form-types";

import { useDepartmentForm } from "./use-department-form";

import { T, useT } from "@transifex/react";

export type DepartmentFormAction = "create" | "update";

interface IProps {
  data: CreateDepartment;
  onUpdate: (data: CreateDepartment) => void;
  onCreate: (data: CreateDepartment) => void;
  loading?: boolean;
}

export const DepartmentForm: React.FC<IProps> = ({
  data,
  onUpdate,
  onCreate,
  loading,
}) => {
  const t = useT();
  const form = useDepartmentForm({
    data,
    onSubmit: (department: CreateDepartment, action: TFormAction) => {
      if (action === "create") {
        onCreate(department);
      } else {
        onUpdate(department);
      }
    },
  });

  const handleFormSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    void form.handleSubmit();
  };

  const selector = (
    state: FormState<{
      name: string;
      id?: string | undefined;
    }>
  ) => {
    return [state.values.id === "" ? "create" : "update"];
  };

  return (
    <form.Subscribe
      selector={selector}
      children={([action]) => (
        <>
          <SheetHeader>
            <SheetTitle>
              {action === "create"
                ? t("Create Department")
                : t("Update Department")}
            </SheetTitle>
            <SheetDescription>
              <T _str="Create a new department here. Click the button when you're done!" />
            </SheetDescription>
          </SheetHeader>
          <form className="space-y-4 text-primary">
            <form.Field
              name="name"
              validators={{
                onChange: departmentSchema.shape.name,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  label={t("Name")}
                  placeholder={t("My department")}
                  required={true}
                />
              )}
            />
            <SheetFooter>
              <SheetClose asChild>
                <LoadingButton
                  loading={loading}
                  onClick={handleFormSubmit}
                  type="submit"
                >
                  {action === "create" ? t("Create") : t("Update")}
                </LoadingButton>
              </SheetClose>
            </SheetFooter>
          </form>
        </>
      )}
    />
  );
};

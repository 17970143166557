import { Company, PaymentPlan } from "@elton/types";
import { Badge, useToast } from "@elton/ui/core";
import { Copy } from "@elton/ui/icons";

import { T, useT } from "@transifex/react";

interface IProps {
  company: Company;
  plan: PaymentPlan | null;
}

export const CompanyInfoWidget: React.FC<IProps> = ({ company, plan }) => {
  const t = useT();
  const { toast } = useToast();

  const handleCopyCustomerID = () => {
    navigator.clipboard.writeText(company.customerNumber);
    toast({
      variant: "info",
      description: t("ID copied to the clipboard."),
    });
  };

  const handleCopyOrgNum = () => {
    navigator.clipboard.writeText(company.organizationNumber!);
    toast({
      variant: "info",
      description: t("Org. num copied to the clipboard."),
    });
  };

  return (
    <div className="space-y-4">
      <div className="flex gap-4">
        <div>
          <span className="w-16 h-16 bg-[#00AB98] flex items-center justify-around rounded-sm">
            <span className="text-white leading-none text-2xl font-extrabold">
              {company.name?.charAt(0).toUpperCase() || ""}
            </span>
          </span>
        </div>
        <div className="space-y-2">
          <h2 className="text-lg font-medium leading-tight">{company.name}</h2>
          <dl className="flex flex-wrap gap-4 md:gap-8 lg:gap-16">
            <div className="space-y-1">
              <dt className="text-xs font-medium text-muted-foreground uppercase">
                <T _str="Plan" />
              </dt>
              <dd className="text-sm font-medium">{plan?.name}</dd>
            </div>
            <div className="space-y-1">
              <dt className="text-xs font-medium text-muted-foreground uppercase">
                <T _str="Status" />
              </dt>
              <dd>
                <Badge variant="positive">
                  <T _str="Active" />
                </Badge>
              </dd>
            </div>
            <div className="space-y-1">
              <dt className="text-xs font-medium text-muted-foreground uppercase">
                <T _str="Customer ID" />
              </dt>
              <dd className="inline-flex gap-1">
                {company.customerNumber && (
                  <>
                    <span className="text-sm font-medium">
                      {company.customerNumber}
                    </span>
                    <button
                      className="hover:opacity-50 transition-opacity"
                      onClick={handleCopyCustomerID}
                    >
                      <Copy className="w-3 h-auto" />
                    </button>
                  </>
                )}
              </dd>
            </div>
            <div className="space-y-1">
              <dt className="text-xs font-medium text-muted-foreground uppercase">
                <T _str="Org. no" />
              </dt>
              <dd className="inline-flex gap-1">
                {company.organizationNumber && (
                  <>
                    <span className="text-sm font-medium">
                      {company.organizationNumber}
                    </span>
                    <button
                      className="hover:opacity-50 transition-opacity"
                      onClick={handleCopyOrgNum}
                    >
                      <Copy className="w-3 h-auto" />
                    </button>
                  </>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

import { cn } from "@elton/ui/lib/utils";

import { LayoutClassNames } from "@/components/layouts/layouts";

interface IProps {
  children: React.ReactNode;
}

export const BlankLayout: React.FC<IProps> = ({ children }) => {
  return (
    <div className={LayoutClassNames.Container}>
      <main
        className={cn(
          "space-y-6",
          LayoutClassNames.SidebarSpaced,
          LayoutClassNames.Content
          //LayoutClassNames.Container,
        )}
      >
        {children}
      </main>
    </div>
  );
};

import { UseMutationOptions } from "@tanstack/react-query";

import {
  createSessionsExport,
  MutationFnParams,
} from "../requests/exports/create-sessions-export";

export const createSessionsExportOptions = (
  companyId: string
): UseMutationOptions<MutationFnParams, Error, MutationFnParams> => ({
  mutationFn: async (data: MutationFnParams) =>
    createSessionsExport(companyId, data),
});

interface IProps {
  className?: string;
}

export const EltonLogoHorizontalIcon: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      width="150"
      height="50"
      viewBox="0 0 150 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <ellipse
        cx="23.9997"
        cy="25.2718"
        rx="21.9997"
        ry="21.9998"
        fill="#00AB98"
      />
      <path
        d="M11.1696 26.5331L24.4931 20.5765C25.6955 20.039 26.9995 21.0389 26.9995 22.4984L26.9995 33.9045C26.9995 35.3381 25.7379 36.3365 24.5436 35.848L11.22 30.3984C9.63503 29.7501 9.60216 27.2338 11.1696 26.5331Z"
        fill="white"
      />
      <path
        d="M36.8304 18.8316L23.5069 12.875C22.3045 12.3375 21.0005 13.3374 21.0005 14.7969L21.0005 26.203C21.0005 27.6366 22.2621 28.635 23.4564 28.1465L36.78 22.6969C38.365 22.0486 38.3978 19.5323 36.8304 18.8316Z"
        fill="white"
      />
      <path
        d="M21.0005 47.0687V33.4001C21.0005 31.6725 22.3436 30.272 24.0005 30.272C25.6573 30.272 27.0005 31.6725 27.0005 33.4001V47.0686C26.0195 47.2024 25.0179 47.2715 24.0002 47.2715C22.9827 47.2715 21.9813 47.2024 21.0005 47.0687Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M21.0005 34.4049V36.8609H27.0009L21.0005 34.4049Z"
        fill="#00AB98"
      />
      <path
        d="M59.8887 11.7082V38.0923H80.0914V33.3809H64.977V26.8602H78.5837V22.1488H64.977V16.4197H79.7145V11.7082H59.8887Z"
        fill="#1A445D"
      />
      <path
        d="M84.0655 38.0923H88.89V11.7082H84.0655V38.0923Z"
        fill="#1A445D"
      />
      <path
        d="M102.233 38.4693C106.04 38.4693 108.377 36.2455 108.904 32.5517L104.457 31.7225C104.306 33.3809 103.59 34.2101 102.346 34.2101C101.027 34.2101 100.311 33.3432 100.311 32.0617V24.1841H107.02V20.2265H100.311V14.3466H95.5237V20.2265H91.8299V24.1841H95.5237V32.0617C95.5237 36.2455 98.1621 38.4693 102.233 38.4693Z"
        fill="#1A445D"
      />
      <path
        d="M119.817 38.4693C125.132 38.4693 129.165 34.6247 129.165 29.1594C129.165 23.7695 125.32 19.8496 119.817 19.8496C114.465 19.8496 110.47 23.6942 110.47 29.1594C110.47 34.474 114.39 38.4693 119.817 38.4693ZM119.817 34.1347C116.953 34.1347 115.181 31.9109 115.181 29.1594C115.181 26.3703 116.953 24.1841 119.817 24.1841C122.682 24.1841 124.453 26.3703 124.453 29.1594C124.453 31.9109 122.682 34.1347 119.817 34.1347Z"
        fill="#1A445D"
      />
      <path
        d="M137.587 38.0923V28.2171C137.587 25.918 138.718 23.7695 141.281 23.7695C143.618 23.7695 144.636 25.5787 144.636 27.7272V38.0923H149.46V26.408C149.46 22.4126 147.048 19.8496 143.015 19.8496C140.866 19.8496 138.756 20.6788 137.587 22.5634V20.2265H132.763V38.0923H137.587Z"
        fill="#1A445D"
      />
    </svg>
  );
};

import React from "react";

import { MainNavigationLarge } from "./content/main-navigation-large.js";
import { MainNavigationSmall } from "./content/main-navigation-small.js";
import { IMainNavigationItem } from "./main-navigation-types.js";

export const MainNavigation: React.FC<{
  items: IMainNavigationItem[];
}> = ({ items }) => {
  return (
    <>
      <MainNavigationSmall className="md:hidden ml-auto" items={items} />
      <MainNavigationLarge className="hidden md:block" items={items} />
    </>
  );
};

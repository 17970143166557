import { createContext, useEffect, useState } from "react";

export type EltonRole = "superadmin" | "user";

export const EltonRoleStoreKey = "elton-x-role";

type RoleProviderProps = {
  children: React.ReactNode;
  defaultRole?: EltonRole;
};

type RoleProviderState = {
  isAdmin: boolean;
  role: EltonRole;
  setRole: (role: EltonRole) => void;
};

const initialState: RoleProviderState = {
  role: "user",
  isAdmin: false,
  setRole: () => null,
};

/**
 * Context for the role provider.
 */
export const RoleProviderContext =
  createContext<RoleProviderState>(initialState);

/**
 * Provides a role context for the application.
 *
 * @param children - The child components to be wrapped by the theme provider.
 * @param defaultRole - The default role to be used if no role is set.
 * @param storageKey - The key used to store the role in local storage.
 * @returns The role provider component.
 */
export function RoleProvider({
  children,
  defaultRole = "user",
}: RoleProviderProps) {
  const [role, setRole] = useState<EltonRole>(
    (window.localStorage.getItem(EltonRoleStoreKey) as EltonRole) ?? defaultRole
  );

  useEffect(() => {
    window.addEventListener("storage", () => {
      setRole(
        (window.localStorage.getItem(EltonRoleStoreKey) as EltonRole) ??
          defaultRole
      );
    });
  });

  const value: RoleProviderState = {
    role: role,
    isAdmin: role === "superadmin",
    setRole: (role: EltonRole) => {
      console.log("Setting role", role);
      localStorage.setItem(EltonRoleStoreKey, role);
      setRole(role);
    },
  };

  return (
    <RoleProviderContext.Provider value={value}>
      {children}
    </RoleProviderContext.Provider>
  );
}

import { startOfMonth } from "date-fns";

type Duration = {
  start: Date;
  end: Date;
};

interface MonthMap {
  [year: number]: Date[];
}

/**
 * Given a start and a end date, returns a map containing arrays of months grouped
 * by each year within the specified date range.
 *
 * @param startOfDuration - The start date of the range.
 * @param endOfDuration - The end date of the range.
 * @returns A map with years as keys and an array of months as values.
 */
export const getMonthsInYears = (duration: Duration): MonthMap => {
  const monthsInYear: MonthMap = {};

  const currentDate = new Date(duration.start);

  while (currentDate <= duration.end) {
    const currentMonth = startOfMonth(currentDate);
    const currentYear = currentMonth.getFullYear();

    monthsInYear[currentYear] = monthsInYear[currentYear] || [];
    monthsInYear[currentYear]!.push(currentMonth);

    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return monthsInYear;
};

import { useEffect } from "react";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";

import { CreateExport } from "@elton/types";

interface IProps {
  data: CreateExport;
  onSubmit: (value: CreateExport) => void;
}

export const useExportForm = ({ data, onSubmit }: IProps) => {
  const form = useForm({
    defaultValues: data,
    validatorAdapter: zodValidator(),
    onSubmit: (values) => {
      return onSubmit(values.value);
    },
  });

  useEffect(() => {
    form.setFieldValue("dateFrom", data.dateFrom);
    form.setFieldValue("dateTo", data.dateTo);
  }, [data, form]);

  return form;
};

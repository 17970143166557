import {
  BasePaymentPlan,
  CreateCompany,
  createCompanySchema,
} from "@elton/types";
import {
  SheetClose,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@elton/ui/core";

import { LoadingButton } from "@/components/buttons/loading-button";
import { InputField } from "@/components/fields/input-field";
import { SelectField } from "@/components/fields/select-field";

import { TFormAction } from "../../form-types";

import { useCreateCompanyForm } from "./use-create-company-form";

import { T, useT } from "@transifex/react";

interface IProps {
  data: CreateCompany;
  paymentPlans: BasePaymentPlan[];
  onCreate: (data: CreateCompany) => void;
  onUpdate: (data: CreateCompany) => void;
  loading?: boolean;
}

export const CreateCompanyForm: React.FC<IProps> = ({
  data,
  paymentPlans,
  onCreate,
  onUpdate,
  loading,
}) => {
  const t = useT();
  const form = useCreateCompanyForm({
    data,
    onSubmit: (company: CreateCompany, action: TFormAction) => {
      if (action === "create") {
        onCreate(company);
      } else {
        onUpdate(company);
      }
    },
  });

  const handleFormSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    void form.handleSubmit();
  };

  return (
    <form.Subscribe
      selector={() => {
        return ["create"];
      }}
      children={([action]) => (
        <>
          <SheetHeader>
            <SheetTitle>
              {action === "create" ? t("Create company") : t("Update company")}
            </SheetTitle>
            <SheetDescription>
              <T _str="Create a new company - Click the button when you're done." />
            </SheetDescription>
          </SheetHeader>
          <form className="grid grid-cols-12 gap-6 text-primary">
            <form.Field
              name="name"
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Company Name")}
                  placeholder={t("My company")}
                  required={true}
                />
              )}
            />
            <form.Field
              name="organizationNumber"
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Organisation Number")}
                  placeholder="xxxxxxxxx"
                  required={true}
                />
              )}
            />
            <form.Field
              name="address"
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Street Address")}
                  placeholder="Edvard Storms gate 2"
                />
              )}
            />
            <form.Field
              name="postalCode"
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full md:col-span-6"
                  label={t("Postal Code")}
                  placeholder="0166"
                />
              )}
            />
            <form.Field
              name="city"
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full md:col-span-6"
                  label={t("City")}
                  placeholder="Oslo"
                />
              )}
            />
            <form.Field
              name="contactName"
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full md:col-span-6"
                  label={t("Contact Name")}
                  placeholder="John Smith"
                />
              )}
            />
            <form.Field
              name="contactPhone"
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full md:col-span-6"
                  label={t("Contact Phone")}
                  placeholder="+47xxxxxxxx"
                />
              )}
            />
            <form.Field
              name="invoiceEmail"
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Invoice email")}
                  placeholder="invoice@example.com"
                />
              )}
            />
            <form.Field
              name="paymentPlanId"
              validators={{
                onChange: createCompanySchema.shape.paymentPlanId,
              }}
              children={(field) => (
                <SelectField
                  data={paymentPlans}
                  keyExtractor={{ key: "id", value: "name" }}
                  field={field}
                  label={t("Payment plan")}
                  placeholder={t("Select a payment plan")}
                  className="col-span-full"
                />
              )}
            />
            <form.Field
              name="discountPercentage"
              children={(field) => (
                <InputField
                  field={field}
                  type="number"
                  className="col-span-full"
                  label={t("Discount percentage")}
                  placeholder="0%"
                  onChange={(e) => field.handleChange(Number(e.target.value))}
                />
              )}
            />
            <SheetFooter className="col-span-full">
              <SheetClose asChild>
                <LoadingButton
                  loading={loading}
                  onClick={handleFormSubmit}
                  type="submit"
                >
                  {action === "create" ? t("Create") : t("Update")}
                </LoadingButton>
              </SheetClose>
            </SheetFooter>
          </form>
        </>
      )}
    />
  );
};

import { CreateCompany } from "@elton/types";

export const defaultCreateCompany: CreateCompany = {
  name: "",
  organizationNumber: "",
  address: undefined,
  postalCode: undefined,
  city: undefined,
  country: undefined,
  contactName: undefined,
  contactPhone: undefined,
  invoiceEmail: undefined,
  discountPercentage: undefined,
};

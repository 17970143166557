import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { CreateDepartment, List, PaymentPlan, User } from "@elton/types";

import { EmployeesPage } from "@/pages/employees-page/employees-page";

import { DefaultListParamsSchema } from "@/api/params/default-list-params";
import { getDepartments } from "@/api/requests/departments/get-departments";
import { getEmployees } from "@/api/requests/employees/get-employees";
import { getCompanyPaymentPlanOverride } from "@/api/requests/payment-plan/get-payment-plan-override";

const SearchParamsSchema = DefaultListParamsSchema.extend({
  open: z.string().optional(),
});

type UsersSearchParams = z.infer<typeof SearchParamsSchema>;

const Route = createFileRoute(
  "/_authenticated/companies/$companyId/_dashboard/employees"
)({
  component: EmployeesPage,
  validateSearch: SearchParamsSchema,
  loaderDeps: ({ search: { offset, limit, filter, sort, open } }) => ({
    offset,
    limit,
    filter,
    sort,
    open,
  }),
  loader: async ({
    params,
    deps,
  }): Promise<{
    employees: List<User>;
    departments: List<CreateDepartment>;
    companyId: string;
    companyPlan: PaymentPlan | null;
  }> => {
    return {
      companyId: params.companyId,
      employees: await getEmployees(params.companyId, deps),
      departments: await getDepartments(params.companyId),
      companyPlan: await getCompanyPaymentPlanOverride(params.companyId),
    };
  },
});

export { Route };
export type { UsersSearchParams };

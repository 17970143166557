import { CellContext, ColumnDef } from "@tanstack/react-table";

import { Account } from "@elton/types";

import { AccountDropdownActions } from "../actions/account-actions";
import { AlignRightColumn, SortableColumn } from "../components/table-columns";

import { useT } from "@transifex/react";

type AccountColumnDef = ColumnDef<Account>[];
type AccountCellContext = CellContext<Account, unknown>;

interface IColumnProps {
  onUpdate: (value: Account) => void;
  onDelete: (value: Account) => void;
  onCopyId: (value: string) => void;
}

export const useAccountColumns = ({
  onUpdate,
  onDelete,
  onCopyId,
}: IColumnProps): AccountColumnDef => {
  const t = useT();

  return [
    {
      accessorKey: "id",
      header: SortableColumn("ID"),
      size: 10,
    },
    {
      accessorKey: "email",
      filterFn: "includesString",
      header: SortableColumn(t("Email")),
      size: 20,
    },
    {
      accessorKey: "firstName",
      filterFn: "includesString",
      header: SortableColumn(t("First name")),
      size: 10,
    },
    {
      accessorKey: "lastName",
      filterFn: "includesString",
      header: SortableColumn(t("Last name")),
    },
    {
      id: "actions",
      enableHiding: false,
      header: () => <AlignRightColumn title={t("Actions")} />,
      cell: ({ row }: AccountCellContext) => {
        return (
          <AccountDropdownActions
            data={row.original}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCopyId={onCopyId}
            className="flex justify-end text-right"
          />
        );
      },
    },
  ];
};

import { forwardRef } from "react";

import { Button } from "@elton/ui/core";
import { Loader } from "@elton/ui/icons";
import { cn } from "@elton/ui/lib/utils";

type Ref = HTMLButtonElement;
type Props = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const LoadingButton = forwardRef<Ref, Props & { loading?: boolean }>(
  ({ children, loading, disabled, className, ...rest }, ref) => {
    return (
      <Button
        disabled={loading || disabled}
        variant="default"
        className={cn("-ml-4", className)}
        ref={ref}
        {...rest}
      >
        {loading ? <Loader className="w-4 h-4 animate-spin" /> : children}
      </Button>
    );
  }
);

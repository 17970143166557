import { UseMutationOptions } from "@tanstack/react-query";

import { CreateCompany } from "@elton/types";

import { createCompany } from "@/api/requests/companies/create-company";
import { deleteCompany } from "@/api/requests/companies/delete-compay";
import { getCompany } from "@/api/requests/companies/get-company";
import { updateCompany } from "@/api/requests/companies/update-company";

export const getCompanyOptions = (companyId: string) => ({
  queryKey: ["company", companyId],
  queryFn: async () => getCompany(companyId),
});

export const createCompanyOptions = (): UseMutationOptions<
  CreateCompany,
  Error,
  CreateCompany
> => ({
  mutationFn: async (data: CreateCompany) => createCompany(data),
});

export const updateCompanyOptions = () => ({
  mutationFn: async ({
    companyId,
    data,
  }: {
    companyId: string;
    data: CreateCompany;
  }) => updateCompany(companyId, data),
});

export const deleteCompanyOptions = (): UseMutationOptions<
  CreateCompany,
  Error,
  string
> => ({
  mutationFn: async (companyId: string) => deleteCompany(companyId),
});

import { UseMutationOptions } from "@tanstack/react-query";

import { CreateDepartment } from "@elton/types";

import { createDepartment } from "../requests/departments/create-department";
import { deleteDepartment } from "../requests/departments/delete-department";
import { getDepartment } from "../requests/departments/get-department";
import { updateDepartment } from "../requests/departments/update-department";

export const getDepartmentOptions = (
  companyId: string,
  departmentId: string
) => ({
  queryKey: ["department", companyId, departmentId],
  queryFn: async () => getDepartment(companyId, departmentId),
});

export const createDepartmentOptions = (
  companyId: string
): UseMutationOptions<CreateDepartment, Error, CreateDepartment> => ({
  mutationFn: async (data: CreateDepartment) =>
    createDepartment(companyId, data),
});

export const updateDepartmentOptions = (): UseMutationOptions<
  CreateDepartment,
  Error,
  CreateDepartment
> => ({
  mutationFn: async (data: CreateDepartment) => updateDepartment(data),
});

export const deleteDepartmentOptions = (): UseMutationOptions<
  CreateDepartment,
  Error,
  string
> => ({
  mutationFn: async (departmentId: string) => deleteDepartment(departmentId),
});

import { useMutation } from "@tanstack/react-query";
import { useRouter } from "@tanstack/react-router";
import { AxiosError } from "axios";

import { useToast } from "@elton/ui/core";

import { deleteEmployeeOptions } from "@/api/options/employee-options";
import { useT } from "@transifex/react";

export const useDeleteEmployeeMutation = () => {
  const t = useT();
  const router = useRouter();
  const { toast } = useToast();

  return useMutation({
    ...deleteEmployeeOptions(),
    onSuccess: () => {
      toast({
        variant: "success",
        description: t("Employee was deleted successfully!"),
      });
      router.invalidate();
    },
    onError: (err: AxiosError<{ message?: string }>) => {
      toast({
        variant: "error",
        description: `${t("Employee could not be deleted.")} ${err?.response?.data?.message ?? ""}`,
      });
    },
  });
};

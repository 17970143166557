import { useMemo } from "react";
import {
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  sub,
} from "date-fns";

import { useT } from "@transifex/react";

export interface DateRangePreset {
  dateFrom: Date;
  dateTo: Date;
  title: string;
}

type GetDateRangePresetsFn = (rd?: Date) => DateRangePreset[];

export const useDateRangePresets: GetDateRangePresetsFn = (
  referenceDate = new Date()
) => {
  const t = useT();

  return useMemo(() => {
    const yesterday = sub(referenceDate, { days: 1 });
    const startOfLastWeek = sub(startOfWeek(referenceDate), { weeks: 1 });
    const endOfLastWeek = endOfWeek(startOfLastWeek);
    const startOfCurrentWeek = startOfWeek(referenceDate);
    const endOfCurrentWeek = endOfWeek(referenceDate);
    const startOfCurrentMonth = startOfMonth(referenceDate);
    const endOfCurrentMonth = endOfMonth(referenceDate);
    const startOfLastMonth = sub(startOfCurrentMonth, { months: 1 });
    const endOfLastMonth = endOfMonth(startOfLastMonth);

    return [
      {
        title: t("Today"),
        dateFrom: referenceDate,
        dateTo: referenceDate,
      },
      {
        title: t("Yesterday"),
        dateFrom: yesterday,
        dateTo: yesterday,
      },
      {
        title: t("Last 7 days"),
        dateFrom: sub(referenceDate, { days: 7 }),
        dateTo: referenceDate,
      },
      {
        title: t("Last 14 days"),
        dateFrom: sub(referenceDate, { days: 14 }),
        dateTo: referenceDate,
      },
      {
        title: t("Last 30 days"),
        dateFrom: sub(referenceDate, { days: 30 }),
        dateTo: referenceDate,
      },
      {
        title: t("This week"),
        dateFrom: startOfCurrentWeek,
        dateTo: endOfCurrentWeek,
      },
      {
        title: t("Last week"),
        dateFrom: startOfLastWeek,
        dateTo: endOfLastWeek,
      },
      {
        title: t("This month"),
        dateFrom: startOfCurrentMonth,
        dateTo: endOfCurrentMonth,
      },
      {
        title: t("Last month"),
        dateFrom: startOfLastMonth,
        dateTo: endOfLastMonth,
      },
    ];
  }, [t, referenceDate]);
};

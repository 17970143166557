import { Locale, supportedLocales } from "@elton/types";

export function getPreferredLocale(defaultLocale: Locale): Locale {
  const browserLocales = navigator.languages ?? [navigator.language];

  for (const locale of browserLocales) {
    const trimmedLocale = locale.trim().split(/-|_/)[0] as Locale;
    if (supportedLocales.has(trimmedLocale)) {
      return trimmedLocale;
    }
  }

  return defaultLocale;
}

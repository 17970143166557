import { useEffect } from "react";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";

import { Account } from "@elton/types";

import { TFormAction } from "../form-types";

interface IProps {
  data: Account;
  onSubmit: (value: Account, action: TFormAction) => void;
}

export const useAccountForm = ({ data, onSubmit }: IProps) => {
  const form = useForm({
    defaultValues: data,
    validatorAdapter: zodValidator(),
    onSubmit: (values) => {
      return onSubmit(values.value, data.id ? "update" : "create");
    },
  });

  useEffect(() => {
    form.setFieldValue("id", data.id);
    form.setFieldValue("firstName", data.firstName);
    form.setFieldValue("lastName", data.lastName);
    form.setFieldValue("email", data.email);
  }, [data, form]);

  return form;
};

import { useMemo } from "react";

import { cn } from "@elton/ui/lib/utils";

export const ProgressBar: React.FC<{ total: number; max: number }> = ({
  total,
  max,
}) => {
  const completionInPercentage = useMemo(
    () => Math.round((total / max) * 100),
    [total, max]
  );

  return (
    <div className="w-full bg-muted rounded-full h-2.5 dark:bg-white overflow-hidden">
      <div
        className={cn("h-2.5 rounded-full bg-positive")}
        style={{ width: `${completionInPercentage}%` }}
      ></div>
    </div>
  );
};

import {
  Avatar,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@elton/ui/core";
import { SunMoon } from "@elton/ui/icons";

import { Theme } from "@/context/theme-context/theme-context";
import { useTheme } from "@/context/theme-context/use-theme";
import { T } from "@transifex/react";

export const ThemeDropdown = () => {
  const { setTheme } = useTheme();

  const handleChangeTheme = (theme: Theme) => {
    setTheme(theme);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-around cursor-pointer">
          <SunMoon className="stroke-primary" />
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => handleChangeTheme("light")}>
            <span>
              <T _str="Light" />
            </span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleChangeTheme("dark")}>
            <span>
              <T _str="Dark" />
            </span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleChangeTheme("system")}>
            <span>
              <T _str="System" />
            </span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

import { useMutation } from "@tanstack/react-query";
import { useRouter } from "@tanstack/react-router";
import { AxiosError } from "axios";

import { useToast } from "@elton/ui/core";

import { createEmployeeOptions } from "@/api/options/employee-options";
import { useT } from "@transifex/react";

export const useCreateEmployeeMutation = (companyId: string) => {
  const t = useT();
  const router = useRouter();
  const { toast } = useToast();

  return useMutation({
    ...createEmployeeOptions(companyId),
    onSuccess: (data) => {
      toast({
        variant: "success",
        description: t("Employee {name} was created successfully!", {
          name: data.name,
        }),
      });
      router.invalidate();
    },
    onError: (err: AxiosError<{ message?: string }>) => {
      toast({
        variant: "error",
        description: `${t("Employee could not be created.")} ${err?.response?.data?.message ?? ""}`,
      });
    },
  });
};

import { createFileRoute, redirect } from "@tanstack/react-router";

import { PageLayout } from "@/pages/_layouts/page-layout/page-layout";
import { ErrorPage } from "@/pages/error-page/error-page";

import SentryAdapter from "@/adapters/sentry-adapter";
import { kindeClient } from "@/api/clients/kinde-client";

const requireAuth = async () => {
  if (!(await kindeClient.getToken())) {
    throw redirect({ to: "/login" });
  }

  await SentryAdapter.setUser();
};

export const Route = createFileRoute("/_authenticated")({
  beforeLoad: requireAuth,
  component: PageLayout,
  errorComponent: ErrorPage,
});

import React from "react";

import { NavigationContentSmall } from "../content/navigation-content-small";
import { NavigationContentLarge } from "../content/navigation-content-large";
import { cn } from "@ui/lib/utils";

// eslint-disable-next-line no-unused-vars
type SidebarRenderItemFn<T> = (item: T, index: number) => React.ReactNode;

interface ISidebarItemBase<T> {
  subItems?: Omit<T, "icon">[]; // FIXME
}

export interface IProps<T> {
  className?: string;
  items: T[];
  current: T | null;
  renderItem: SidebarRenderItemFn<T>;
  renderSubItem: SidebarRenderItemFn<T>;
}

export const SidebarNavigation = <T,>({
  className,
  ...rest
}: IProps<T & ISidebarItemBase<T>>) => {
  return (
    <>
      <NavigationContentSmall
        {...rest}
        className={cn("md:hidden pb-4", className)}
      />
      <NavigationContentLarge
        {...rest}
        className={cn("hidden md:flex", className)}
      />
    </>
  );
};

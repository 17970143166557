import { Button } from "@elton/ui/core";

import { T } from "@transifex/react";

export const ErrorPage = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-6 px-4 md:px-6">
      <TriangleAlertIcon className="text-gray-500 dark:text-gray-400 h-14 w-14" />

      <div className="space-y-4 text-center">
        <h2 className="text-3xl font-bold tracking-tight md:text-4xl">
          <T _str="It appears we've encountered an issue" />
        </h2>
        <p className="text-gray-500 dark:text-gray-400 max-w-2xl mx-auto">
          <T _str="Don't worry, our team of experts is working diligently to resolve the problem. Let's try this again and get you back on track." />
        </p>
      </div>
      <div className="flex flex-col gap-4 items-center justify-center min-[400px]:flex-row">
        <Button>
          <T _str="Return to Safety" />
        </Button>
        <Button
          className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
          variant="outline"
        >
          <T _str="Try Again" />
        </Button>
      </div>
      <div className="text-sm text-gray-500 dark:text-gray-400 max-w-2xl text-center">
        <T _str="If you require further assistance, please don't hesitate to contact our support team." />
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function TriangleAlertIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
      <path d="M12 9v4" />
      <path d="M12 17h.01" />
    </svg>
  );
}

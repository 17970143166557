import { ChargeSession, List } from "@elton/types";

import { getServicesClient } from "../../clients/api-client";

export const getSpendingsSessions = async (
  companyId: string,
  params?: {
    dateFrom?: string;
    dateTo?: string;
  }
): Promise<List<ChargeSession>> => {
  const res = await getServicesClient().post(
    `/companies/${companyId}/sessions`,
    { ...params }
  );

  return res.data;
};

import axios, { InternalAxiosRequestConfig } from "axios";

import SentryAdapter from "@/adapters/sentry-adapter";
import { kindeClient } from "@/api/clients/kinde-client";
import {
  EltonRole,
  EltonRoleStoreKey,
} from "@/context/role-context/role-context";

const defaultHeaders = {
  "Content-Type": "application/json",
};

export const axiosServicesClient = axios.create({
  baseURL: import.meta.env.VITE_ELTON_SERVICES_URL,
  headers: defaultHeaders,
});

export const getServicesClient = () => axiosServicesClient;

axiosServicesClient.interceptors.request.use(withBearerToken, function (error) {
  console.error("error in request interceptor", error);
  SentryAdapter.captureException(error);
  return Promise.reject(error);
});

axiosServicesClient.interceptors.response.use(
  (response) => {
    if (!response || !response.headers) {
      return response;
    }

    const currentEltonRole = localStorage.getItem(EltonRoleStoreKey);
    const eltonRole = parseEltonRole(response.headers["x-elton-roles"] || "");

    if (currentEltonRole !== eltonRole) {
      localStorage.setItem(EltonRoleStoreKey, eltonRole);
      window.dispatchEvent(new Event("storage"));
    }

    return response;
  },
  (error) => {
    console.error("error in response interceptor", error);
    SentryAdapter.captureException(error);
    return Promise.reject(error);
  }
);

async function withBearerToken(config: InternalAxiosRequestConfig) {
  const token = await kindeClient.getToken();

  config.headers.Authorization = `Bearer ${token}`;

  return config;
}

const parseEltonRole = (str: string): EltonRole => {
  return str.includes("b2b_portal:superadmin") ? "superadmin" : "user";
};

import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@ui/lib/utils";
import { SearchIcon, XIcon } from "lucide-react";
import { Button } from "./button";

const inputVariants = cva(
  cn(
    "flex h-10 w-full bg-background px-3 py-2 text-sm transition-shadow",
    "file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-70",
    "rounded-md border ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
  ),
  {
    variants: {
      variant: {
        default: "border-input",
        destructive: "focus:border-input border-red-500",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, variant, type, ...props }, ref) => {
    return (
      <>
        <input
          type={type}
          className={cn(inputVariants({ className, variant }))}
          ref={ref}
          {...props}
        ></input>
      </>
    );
  }
);
Input.displayName = "Input";

export interface SearchInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">,
    VariantProps<typeof inputVariants> {
  containerClassName?: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (_: string) => void;
}

const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  (
    {
      className,
      containerClassName,
      variant,
      type,
      onChange,
      defaultValue,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = React.useState(defaultValue);

    const handleChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const stringValue = String(e.target.value);
        setValue(stringValue);
        onChange?.(stringValue);
      },
      []
    );

    const handleReset = React.useCallback(() => {
      const stringValue = "";
      setValue(stringValue);
      onChange?.(stringValue);
    }, []);

    return (
      <fieldset className={cn(containerClassName, "relative")}>
        <div className="absolute inset-y-0 w-12 flex items-center justify-around">
          <SearchIcon className="h-4 w-4 opacity-50" />
        </div>
        <input
          type={type}
          className={cn(inputVariants({ className, variant }), "pl-10")}
          ref={ref}
          onChange={handleChange}
          value={value}
          {...props}
        ></input>
        <Button
          className={cn("absolute right-0 top-0", !value && "hidden")}
          variant="ghost"
          onClick={handleReset}
        >
          <XIcon className="w-4 h-4 text-muted-foreground" />
        </Button>
      </fieldset>
    );
  }
);
SearchInput.displayName = "SearchInput";

export { Input, SearchInput };

import { z } from "zod";

import { DefaultListParamsSchema } from "./default-list-params";

export const dateRangeParamsSchema = DefaultListParamsSchema.extend({
  dateFrom: z.string().optional().default(""),
  dateTo: z.string().optional().default(""),
});

export type DateRangeParams = z.infer<typeof dateRangeParamsSchema>;

export const getDateRangeLoaderDeps = (opts: {
  search: unknown;
}): DateRangeParams => {
  return dateRangeParamsSchema.parse(opts.search);
};
